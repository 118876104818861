<template>
  <div>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Last 30 days
    </h3>
    <div class="grid grid-cols-6">
      <dl
        v-for="item in stats"
        :key="item.name"
        class="mt-5 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x mx-1"
      >
        <div class="px-4 py-5 sm:p-6 ">
          <div class="col-span-1">
            <dt class="text-base font-normal text-gray-900">
              {{ item.name }}
            </dt>
            <dd class="mt-1 flex justify-between items-baseline">
              <div
                class="flex items-baseline text-2xl font-semibold primary_text"
              >
                {{ item.stat }}
                <span class="ml-2 text-sm font-medium text-gray-500">
                  from {{ item.previousStat }}
                </span>
              </div>
              <div
                :class="[
                  item.changeType === 'increase'
                    ? 'bg-green-100 text-green-800'
                    : item.changeType === 'decrease'
                    ? 'bg-red-100 text-red-800'
                    : 'bg-gray-100 text-gray-800',
                  'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-md font-medium md:mt-2 lg:mt-0',
                ]"
              >
                <ArrowSmUpIcon
                  v-if="item.changeType === 'increase'"
                  class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 text-green-500"
                  aria-hidden="true"
                />
                <ArrowSmDownIcon
                  v-else-if="item.changeType === 'decrease'"
                  class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 text-red-500"
                  aria-hidden="true"
                />
                <MinusSmIcon
                  v-else
                  class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 text-gray-500"
                  aria-hidden="true"
                />
                <span class="sr-only">
                  {{
                    item.changeType === "increase" ? "Increased" : "Decreased"
                  }}
                  by
                </span>
                {{ item.change }}
              </div>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  MinusSmIcon,
} from "@heroicons/vue/solid";

export default {
  name: "StatsHeading",
  props: ["stats", "data"],
  components: { ArrowSmDownIcon, ArrowSmUpIcon, MinusSmIcon },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
