export default {
  "replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replied"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "spellCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spell check"])},
  "alertTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert tickets"])},
  "expert_stats_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expert stats version"])},
  "sbc_aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sbc aliases"])},
  "ssh_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh access"])},
  "ssh_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh username"])},
  "ssh_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh password"])},
  "ssh_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh key"])},
  "database_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["database name"])},
  "database_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["database username"])},
  "database_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["database password"])},
  "backups_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backups location"])},
  "fetch_backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fetch backups"])},
  "backups_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backups frequency"])},
  "backups_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backups host"])},
  "backups_host_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backups host username"])},
  "backups_host_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backups host password"])},
  "cdr_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cdr location"])},
  "fetch_cdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fetch cdr"])},
  "push_cdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["push cdr"])},
  "cdr_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cdr frequency"])},
  "csv_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csv location"])},
  "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recordings"])},
  "recordings_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recordings location"])},
  "fetch_recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fetch recordings"])},
  "push_recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["push recordings"])},
  "recordings_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recordings frequency"])},
  "saveAndSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save & submit"])},
  "newAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new audit"])},
  "unsolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unsolved"])},
  "solved_within_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solved within audit"])},
  "correct_before_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correct before audit"])},
  "editAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit audit"])},
  "due_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
  "next_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next audit date"])},
  "audited_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audited date"])},
  "audited_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audited by"])},
  "pbx_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language settings"])},
  "pbx_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 3CX updates"])},
  "pbx_backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup settings"])},
  "pbx_firewall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firewall settings"])},
  "pbx_in_out_bound_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound and outbound rules settings"])},
  "pbx_contacts_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts settings"])},
  "pbx_user_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users permissions settings"])},
  "pbx_provider_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator specific settings"])},
  "pbx_emergency_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency numbers settings"])},
  "pbx_codecs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codecs settings"])},
  "pbx_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails languages settings"])},
  "pbx_provision_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random check of 10% of the extensions"])},
  "pbx_provision_callqueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random check of 10% of the call_queues"])},
  "server_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server billing mode"])},
  "server_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server name"])},
  "server_sizing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server sizing"])},
  "server_ssh_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public SSH keys installed"])},
  "editHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Host"])},
  "newHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Host"])},
  "pbx3cx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX 3cx"])},
  "pbxAudits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX Audits"])},
  "pbx_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX Logs"])},
  "pbx3cxHosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX 3cx Hosts"])},
  "pleaseEnterValidHostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid host"])},
  "3cxTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3cx tools"])},
  "callLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call logs"])},
  "hostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host name"])},
  "externalFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["external file"])},
  "quizQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quiz question"])},
  "quizName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quiz name"])},
  "multipleChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multiple choice"])},
  "trueOrFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true or false"])},
  "newProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new program"])},
  "newQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new quiz"])},
  "newLesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new lesson"])},
  "newCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new course"])},
  "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New ticket"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courses"])},
  "programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programs"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finish"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
  "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audience"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published"])},
  "buyable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buyable"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
  "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excerpt"])},
  "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lessons"])},
  "goTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to"])},
  "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action successful"])},
  "collectAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access collect"])},
  "collectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect type"])},
  "openClientCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open client collect"])},
  "ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ports"])},
  "trunk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trunk"])},
  "trunkSimultaneousCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simultaneous calls"])},
  "trunkHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "trunkAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "trunkPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "collectionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collects list"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restorer"])},
  "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical"])},
  "pbxServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX Server"])},
  "ipAddressLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local IP address"])},
  "ipDynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic IP address"])},
  "portSip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIP Port"])},
  "licenceKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence key"])},
  "adminCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin credentials"])},
  "adminCredentialsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If no password is provide, it will be randomly generated at the validation"])},
  "adminUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin username"])},
  "adminPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin password"])},
  "portHttp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port HTTP"])},
  "portHttps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port HTTPS"])},
  "portSIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port SIP"])},
  "portTunnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port Tunnel"])},
  "dnsSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS Suffixe"])},
  "dnsLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS Local"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "trunkExternalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trunk external number"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Information"])},
  "customerInfoDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main details and 3CX infos"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "newMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New note"])},
  "memos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notes"])},
  "collectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect detail"])},
  "newDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New document"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documents"])},
  "documentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Description"])},
  "bulkYear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " year"]), _normalize([_interpolate(_named("count")), " years"])])},
  "bulkMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " month"]), _normalize([_interpolate(_named("count")), " months"])])},
  "bulkWeek": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " week"]), _normalize([_interpolate(_named("count")), " weeks"])])},
  "bulkDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " day"]), _normalize([_interpolate(_named("count")), " days"])])},
  "bulkHour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " hour"]), _normalize([_interpolate(_named("count")), " hours"])])},
  "bulkMinute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " minute"]), _normalize([_interpolate(_named("count")), " minutes"])])},
  "bulkSecond": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " second"]), _normalize([_interpolate(_named("count")), " seconds"])])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "lessThanMinute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less than a minute"])},
  "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ago"])},
  "host": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["host"]), _normalize(["hosts"])])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
  "goBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back home"])},
  "resultNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results not found"])},
  "resultNotFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They is no contact found with this phone number"])},
  "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sub-category"])},
  "subCategoriesNumb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No sub-category"]), _normalize(["One sub-category"]), _normalize([_interpolate(_named("count")), " sub-categories"])])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
  "categoryShowable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showable for client"])},
  "showable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showable"])},
  "noShowable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No showable"])},
  "noDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
  "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any data at the moment"])},
  "noDataFoundInvoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoices"])},
  "noDataFoundInvoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any invoices."])},
  "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
  "noResultFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
  "noResultFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
  "noDataFoundOpenTicketsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any open tickets."])},
  "noDataFoundClosedTicketsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any closed tickets."])},
  "noResultFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try adjusting your result of filtering otherwise."])},
  "noDataFoundDocumentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Documents"])},
  "noDataFoundDocumentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any documents. Contact your line manager if it's not supposed to happen."])},
  "noDataFoundContactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Contacts"])},
  "noDataFoundContactDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new contact or by uploading a contacts list."])},
  "noDataFoundCtiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No CTI"])},
  "noDataFoundCtiDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new CTI configuration."])},
  "noDataFoundRoutingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Geographic Routing"])},
  "noDataFoundRoutingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new geographic routing."])},
  "noDataFoundCallQueueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Callqueues"])},
  "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new callqueue."])},
  "noDataFoundCallQueueGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Callqueue Groups"])},
  "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new callqueue group."])},
  "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on 'Add New Time Slot' button."])},
  "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons."])},
  "noDataFoundSMSListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SMS Lists"])},
  "noDataFoundSMSListDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new SMS list."])},
  "noDataFoundElementGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new group."])},
  "noDataFoundDashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Dashboard"])},
  "noDataFoundDashboardDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by selected elements and period."])},
  "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
  "mandateSignatureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate signature date"])},
  "mandateReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate unique reference"])},
  "assignAutomatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assign automatically (leave empty)"])},
  "updateClientRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update client records"])},
  "mandateGoCardless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate GoCardless"])},
  "noKeyProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no key provided"])},
  "businessInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business information"])},
  "shippingDeliveryInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping, delivery and installation"])},
  "technicalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical information"])},
  "billingCopies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing for copies (print products)"])},
  "recurringBillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring billing"])},
  "initialBillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time billing"])},
  "customerFilesDetailHeaderTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Folder ", _interpolate(_named("name"))])},
  "customerFilesDetailHeaderSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fileType")), " ", "#", _interpolate(_named("id"))])},
  "personnalProfessionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service rendered to a person, professional training"])},
  "mandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandate"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "customValueGoCardless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom value of the GoCardless account"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "ticketStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket statuses"])},
  "statusName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status name"])},
  "statusColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a status color"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "teamTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team tickets"])},
  "lastModification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last edited at"])},
  "emptyStateTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No ", _interpolate(_named("title"))])},
  "emptyStateSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You don't have ", _interpolate(_named("title")), ". Contact your supervisor if this is not supposed to happen."])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "newContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contact"])},
  "newContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contract"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "interviewedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interviewed at"])},
  "applyRecruitmentToCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a candidate for the position"])},
  "applicationUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application updated successfully"])},
  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
  "addToCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a candidate"])},
  "interviewsHeadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["List of applications in the recruitment file for the post of ", _interpolate(_named("recruitment"))])},
  "interviewsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants List"])},
  "recruitmentFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruitment Filters"])},
  "newFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New filter"])},
  "newEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New employee"])},
  "newEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New employer"])},
  "quickSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick search for candidates and employers"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
  "candidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate"])},
  "selectAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select AM"])},
  "selectPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select PM"])},
  "inputCantBeNull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field can't be null"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employee"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "attended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attended"])},
  "timeInMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time (in minutes)"])},
  "noTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No title"])},
  "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
  "seeMore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("user")), " profile"])},
  "seeCustomer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("customer")), " page"])},
  "seeTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["See more of ticket #", _interpolate(_named("id"))])},
  "not_attended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not attended"])},
  "matching_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching requirements"])},
  "not_matching_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not matching requirements"])},
  "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
  "not_interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not interested"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "candidateAppliedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new candidate has been linked to this recruitment file"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "specialities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialities"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employees"])},
  "employers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employers"])},
  "candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees and Candidates"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
  "noProvided": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " not provided"])},
  "deleteCandidate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete candidate ", _interpolate(_list(0))])},
  "deleteEmployer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete employer ", _interpolate(_list(0))])},
  "deleteRecruitment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete recruitment folder ", _interpolate(_list(0))])},
  "deleteTicketStatus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ticket status ", _interpolate(_named("name"))])},
  "deleteTicketCategory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ticket category ", _interpolate(_named("name"))])},
  "wishToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to continue ?"])},
  "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
  "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "arrivedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrived date"])},
  "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
  "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
  "language1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language 1"])},
  "language2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language 2"])},
  "language3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language 3"])},
  "otherPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative number"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address 1"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address 2"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "specialitiesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign up to 4 specialities to this candidate"])},
  "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
  "speciality1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality 1"])},
  "speciality2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality 2"])},
  "speciality3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality 3"])},
  "speciality4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality 4"])},
  "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "profilePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil photo"])},
  "otherPhoneDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify, if applicable"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])},
  "disponibilityHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate disponibility"])},
  "disponibilityHeadlineDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide disponibility information, like availability date or week day disponibility"])},
  "civilStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil status"])},
  "civilStatusDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the candidate's civil and administrative status"])},
  "modificationSavedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification saved successfully"])},
  "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employer"])},
  "deleteSpeciality": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete speciality ", _interpolate(_list(0)), " (", _interpolate(_list(1)), ")"])},
  "deleteNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete note"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "landlinePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline phone"])},
  "mobilePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone"])},
  "startOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start on"])},
  "editOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Organization"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "interviewDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview date"])},
  "availableAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available at"])},
  "levelOfStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level of study"])},
  "diplomas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomas"])},
  "diplomas2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomas (2)"])},
  "diplomas3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomas (3)"])},
  "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
  "salaryBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary before"])},
  "salaryAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aalary after"])},
  "hobby1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby 1"])},
  "hobby2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby 2"])},
  "hobby3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby 3"])},
  "commutingInformations1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commuting informations (1)"])},
  "commutingInformations2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commuting informations (2)"])},
  "commutingInformations3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commuting informations (3)"])},
  "hasAllergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergy"])},
  "hasAllergyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please specify the applicant's allergies, if any"])},
  "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "allergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergie(s)"])},
  "availableDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified if the candidate is available for future contracts"])},
  "availableForCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for country"])},
  "availableForAbroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for abroad"])},
  "availableForHoused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for housed"])},
  "availableForNotHoused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for not housed"])},
  "availableForFullTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for full time"])},
  "availableForPartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for part time"])},
  "availableForPartTimeAm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available For Part Time am"])},
  "availableForPartTimePm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available For Part Time pm"])},
  "availableWeekEndAm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available weekend all mornings"])},
  "availableWeekEndPm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available weekend all afternoons"])},
  "availableForWeekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available For Weekend"])},
  "availableForExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available For Extras"])},
  "helpToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help with searching"])},
  "helpToSearchDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this tool to quickly search for candidates and employers across our entire platform. You can also use the search modifiers found in the footer below to limit the results to just candidates or employers."])},
  "forHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for help"])},
  "forEmployers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for employers"])},
  "forCandidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for candidates"])},
  "forEmployersLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to looking for employers"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "lastUpdateOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last update was on"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "notSpecify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not specify"])},
  "MonAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon AM"])},
  "MonPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon PM"])},
  "TueAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue AM"])},
  "TuePM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue PM"])},
  "WedAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed AM"])},
  "WedPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed PM"])},
  "ThuAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu AM"])},
  "ThuPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu PM"])},
  "FriAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri AM"])},
  "FriPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri PM"])},
  "SatAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat AM"])},
  "SatPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat PM"])},
  "SunAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun AM"])},
  "SunPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun PM"])},
  "newSpeciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new speciality"])},
  "newSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site addition"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "generateNewCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new CV"])},
  "addYourComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your comment..."])},
  "commentOnProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General comment on all experiences"])},
  "readFullProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read full profile"])},
  "fullResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full resume (with contact details)"])},
  "truncatedResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truncated version resume (without contact details)"])},
  "sendResumeByEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only the CV without contact details will be sent"])},
  "resumeSuccessfullySend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume successfully send"])},
  "downloadsAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["languages"])},
  "mainContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main contact"])},
  "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced settings"])},
  "contractTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract title"])},
  "applicantInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant information"])},
  "mainInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main information"])},
  "mainInformationDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Main information about the ", _interpolate(_named("infoType"))])},
  "personalDetailsAndApplication": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Personal file data established on the ", _interpolate(_named("userType"))])},
  "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
  "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
  "personalInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide the information below to establish a user profile"])},
  "disponibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibility"])},
  "itemDeletedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " deleted successfully"])},
  "itemAddedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " added successfully"])},
  "itemUpdatedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " updated successfully"])},
  "notePostedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note posted successfully"])},
  "generateNewCvDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any CV yet but you can create a new one by clicking on this area"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "ifApplicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if applicable"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marketing"])},
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activate"])},
  "addFamilyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add family member"])},
  "familyInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family informations"])},
  "familyCompositionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family composition list"])},
  "familyComposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family composition"])},
  "familyCompositionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly describe the composition of the family"])},
  "familyInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family information"])},
  "familyInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify family information, dwelling details and access"])},
  "habitationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitation information"])},
  "habitationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail sur le logement et les accès"])},
  "habitationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
  "habitationFloorArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living area"])},
  "habitationNumberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rooms"])},
  "habitationOtherInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others accommodation informations"])},
  "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
  "accessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accessDescription"])},
  "habitationFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
  "habitationDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door code"])},
  "habitationInterphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom"])},
  "habitationKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "addUsersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to add new users ?"])},
  "addUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Users"])},
  "smsLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("charNumber")), " characters for ", _interpolate(_named("smsNumber")), " SMS"])},
  "campaignReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign ready"])},
  "addNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
  "campaignInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign information"])},
  "campaignDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign detail and statistics"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "newOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New organisation"])},
  "typeYourMessageHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your message here..."])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "errorRecipientsLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a recipients list or a recipients"])},
  "errorAccountId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide an account"])},
  "globalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Information"])},
  "recipientsListsInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients Information"])},
  "recipientsListsInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose between a recipients list and/or few recipients"])},
  "accountInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account information"])},
  "campaignCreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne created successfully"])},
  "newOrganisationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create new organisation by completing form below"])},
  "accountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the account for this campaign"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "smsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of SMS"])},
  "inputErrorInvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, provide a valid email address"])},
  "otherInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others informations"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
  "salaryExpectations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary offered"])},
  "maritalStatusExpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired marital status"])},
  "ageMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum age required"])},
  "ageMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum age required"])},
  "languageExpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired language"])},
  "otherInformationsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other information about the applicant"])},
  "recruitmentFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recruitment folder"])},
  "otherInformationsContractDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other information about the contract"])},
  "otherInformationsFileDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Other information about the ", _interpolate(_named("infoType"))])},
  "updateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update user"])},
  "updateUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new informations below"])},
  "maritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital status"])},
  "numberOfChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of child"])},
  "coupleWithEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couple with another employee"])},
  "expeditorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expeditor name"])},
  "permissionToEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "permissionToEditDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to the user the permission to edit"])},
  "permissionToManageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users"])},
  "permissionToManageUsersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to the user the permission to manage users"])},
  "permissionToManageLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage recipients lists"])},
  "permissionToManageListsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to the user the permission to manage recipients lists"])},
  "removeUsersToAccountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one or more users below if you wish to remove them from the account"])},
  "expeditorNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name displayed to the recipient when receiving SMS"])},
  "smsTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of SMS"])},
  "smsMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the content of the SMS"])},
  "recipientInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the informations of the new recipient here"])},
  "recipientListInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the informations of the new list here"])},
  "disactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disactivate"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "marketingDisclaimerPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful ! For all marketing SMS, the message must finish by"])},
  "marketingDisclaimerPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" It's mandatory in order to provide your recipients with a means of unsubscribing and to comply with the obligations of the CNIL"])},
  "recipientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Information"])},
  "recipientInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the recipients of your SMS here. You can also combine this with a recipients list"])},
  "campaignNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your campaign name"])},
  "signinToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "accountCreatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account was created on"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "newPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must contain one uppercase, one lowercase, one number"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
  "ownedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owned by"])},
  "ownerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner email"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ready"])},
  "newCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new campaign"])},
  "newRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new recipient"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new account"])},
  "newAccountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create new account by completing form below"])},
  "newList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new list"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "billingMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing mode"])},
  "beganDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Began at"])},
  "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
  "updateOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update organisation"])},
  "updateCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update SMS Campaign"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid e-mail address or password"])},
  "organisationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Information"])},
  "organisationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation details"])},
  "organisationInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation you related and details about it"])},
  "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campaign"])},
  "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campaigns"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recipient"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
  "recipientsLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients Lists"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relationship"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gender"])},
  "noDataFoundUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users"])},
  "noDataFoundUsersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They are no users for this account"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "usersList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["List of users in the account ", _interpolate(_named("accountName")), ". You can update the users of the account here."])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accounts"])},
  "accountSectionHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Account ", _interpolate(_named("accountName"))])},
  "noNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notes have been posted yet"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "writeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a description..."])},
  "updateAccountUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update account users"])},
  "goodMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good morning"])},
  "goodAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good afternoon"])},
  "goodEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good evening"])},
  "passwordResetMustContain12Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least 12 characters"])},
  "passwordResetMustContainOneUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least one uppercase letter"])},
  "passwordResetMustContainOneSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least one special character"])},
  "passwordResetMustContainOneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUST contain at least one number"])},
  "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "callQueueNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["callqueue number"])},
  "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select rows"])},
  "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " rows"])},
  "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contract"])},
  "externalEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External employer"])},
  "externalEmployerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify employer name"])},
  "addNewContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contracts"])},
  "noContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contracts"])},
  "noContractDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new contract"])},
  "agencyContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency contract"])},
  "recruitments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recruitments"])},
  "addNewPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New position"])},
  "noPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recruitments file"])},
  "noPositionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by creating a new recruitment file"])},
  "newPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New position"])},
  "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "listsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list name"])},
  "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report list"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])},
  "numberOfCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of calls"])},
  "numberOfAnsweredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of answered calls"])},
  "numberOfCallsAnsweredByAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of calls answered by another"])},
  "numberOfCallsTransferredByAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of calls transferred by agent"])},
  "numberOfAbandonedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of abandoned calls"])},
  "numberOfCallsDeterred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of calls deterred"])},
  "averageWaitingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average waiting time"])},
  "averageWaitingTimeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average waiting time in queue"])},
  "averageDropoutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average dropout time"])},
  "averageCallDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average call duration"])},
  "queueDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["queue detail"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
  "didCallers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DID Callers"])},
  "numberOfRepeatedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of repeated calls"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
  "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal details"])},
  "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Token"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full name"])},
  "avgOpenedMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average opened"])},
  "avgClosedMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average closed"])},
  "avgDurationMinutesMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average duration"])},
  "avgTimeMinutesMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average time"])},
  "avgPointsPerTicketMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average points per ticket"])},
  "avgOpenedWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average opened"])},
  "avgClosedWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average closed"])},
  "avgDurationMinutesWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average duration"])},
  "avgTimeMinutesWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average time"])},
  "avgPointsPerTicketWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average points per ticket"])},
  "generalAvgStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general average stats"])},
  "ticketingStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketing stats"])},
  "perCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per customer"])},
  "statsPerUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per user"])},
  "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reference"])},
  "ticketDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket date"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
  "invoicingTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoicing ticket"])},
  "dailyPointSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daily point summary"])},
  "legalRepresentative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal representative"])},
  "invoicesRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire des factures"])},
  "technicalContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact technique"])},
  "ticketingDoNotNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support: never send notifications to this contact"])},
  "ticketingCopyToSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support: copy notifications to sales"])},
  "newslettersRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletters recipient"])},
  "cfdToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate a new call flow token"])},
  "idCodeFieldRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required when no calling number provided."])},
  "invoicing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer files"])},
  "customer-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer files"])},
  "ticketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
  "smart-routings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart routings"])},
  "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS services"])},
  "webradios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web radios"])},
  "pbx-hosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX hosts"])},
  "expert-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experts statistics"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
  "information-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations collection"])},
  "contactDetailView": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contact detail - ", _interpolate(_named("entity"))])},
  "contactLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts list"])},
  "allPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full access"])},
  "client.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "client.accountant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountant"])},
  "client.technician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technician"])},
  "createPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create permission"])},
  "editPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit permission"])},
  "deletePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete permission"])},
  "viewPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View permission"])},
  "updatePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update permission"])},
  "writePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write permission"])},
  "permission.*": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All access"])},
  "permission.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to add"])},
  "permission.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to edit"])},
  "permission.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to delete"])},
  "permission.view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to read"])},
  "permission.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to update"])},
  "permission.write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to write"])},
  "permission.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to send"])},
  "permission.enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to activate/disactivate"])},
  "hasUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An user is linked to this contact"])},
  "hasNoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribution to credential in progress"])},
  "chooseOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose option"])},
  "signedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signed at"])},
  "pointsAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points alert"])},
  "openedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened by"])},
  "assignedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
  "hostOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Systems"])},
  "hostSoftwares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwares"])},
  "hostRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Roles"])},
  "hostTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Types"])},
  "hostShells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Shells"])},
  "hostFunctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Functions"])},
  "hostSuppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Suppliers"])},
  "datacenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datacenters"])},
  "hostServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Services"])},
  "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template name"])},
  "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "pointsAdditionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point addition type"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report"])},
  "opens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contracts"])},
  "incomingEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Emails"])},
  "ticketsforTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "ticketsForTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
  "takeContactOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take contact out of the ticket"])},
  "contractType1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points-based maintenance contract"])},
  "contractType2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly points maintenance contract"])},
  "contractType3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat rate computer maintenance contract"])},
  "contractType4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone maintenance contract"])},
  "contractType5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer and telephone maintenance contract"])},
  "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update at"])},
  "lastUpdatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update at"])},
  "pointLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("points_current")), " pts left"])},
  "contractType6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-billable"])},
  "customerReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer report"])},
  "ticketsUnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets unassigned"])},
  "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tickets"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "allTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All tickets"])},
  "noDataFoundTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Tickets"])},
  "noDataFoundTicketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any results for this filter"])},
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuration"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed"])},
  "ticketClosedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket closed successfully."])},
  "ticketOpenedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket open successfully."])},
  "somethingWrongHappened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something wrong happened."])},
  "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
  "captureFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture/Free"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "grab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grab"])},
  "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the ticket"])},
  "closeTicketNumb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Close the ticket #", _interpolate(_named("id"))])},
  "closeTicketConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the problem has been resolved before closing this support ticket."])},
  "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category name"])},
  "selectSurvey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a survey"])},
  "subCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-category name"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
  "selectContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a contract"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "deleteTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detele ticket #", _interpolate(_named("id"))])},
  "deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful, you are about to delete this item permanently. Would you like to continue ?"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "ticketDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket deleted successfully"])},
  "ticketAssignedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket assigned successfully."])},
  "ticketUnassignedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket unassigned successfully."])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
  "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open new ticket"])},
  "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the ticket"])},
  "reopenTicketNumb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Open the ticket #", _interpolate(_named("id"))])},
  "reopenTicketConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to reopen this support ticket. Do you want to continue ?"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "internalReplyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This content is an internal reply"])},
  "onCallDuty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Call Duty"])},
  "openPbxHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
  "spentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spent on"])},
  "timeSpent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Time spent: ", _interpolate(_named("numb")), " min"])},
  "timeSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time sheet"])},
  "grabAndGoToTicketDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grab and go to ticket detail"])},
  "closedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed date"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical"])},
  "selectPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a priority"])},
  "selectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a status"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "ticketClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket closed"])},
  "chooseClientThenContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a new ticket, you must first choose the client associated to the ticket and at least one of its contact"])},
  "ticketsClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket closed"])},
  "provideClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must provide in a contact from a client"])},
  "prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prerequisites"])},
  "ticketOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket open"])},
  "nextRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next renewal"])},
  "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
  "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice ID"])},
  "buyingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying Price"])},
  "sellingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling Price"])},
  "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended (without invoice)"])},
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
  "serviceStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Start"])},
  "lastInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Invoice"])},
  "addressEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "folders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["folders"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
  "uploadFileDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file or drag and drop"])},
  "folderSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Settings"])},
  "billingFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Frequency"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "accountEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account email address"])},
  "readyToBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to bill"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "memoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a memo..."])},
  "terminated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminated"])},
  "currentlyBeingDeployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently being deployed"])},
  "quoteInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote in progress"])},
  "consumptionsOnInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumptions on invoices"])},
  "agentIntoFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent related to the folder"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
  "deleteEntity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("entity"))])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "salesRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Representative"])},
  "salesManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Manager"])},
  "techRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technician"])},
  "techManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Manager"])},
  "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
  "newBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New billing"])},
  "licenceMargins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence margins"])},
  "licenceBillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Licences"])},
  "oneOffMargins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-off margins"])},
  "oneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-off"])},
  "lastBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last billing"])},
  "recurringBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring billing"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expand"])},
  "recurringMargins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring margins"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
  "viewLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View less"])},
  "goToWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to 3CX"])},
  "elementIsMandatory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("element")), " is mandatory"])},
  "copyPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le mot de passe"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "contractInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Information"])},
  "resDateShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Res. date"])},
  "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Account"])},
  "selectAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an account"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "noAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attachments found"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
  "autoRenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto renew"])},
  "activityFeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux d'activité"])},
  "noMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by sending a message"])},
  "pointsTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total points"])},
  "pointsCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points current"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open tickets"])},
  "closedTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed tickets"])},
  "successfullyCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully copied"])},
  "bannerSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sandbox"])},
  "bannerProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["production"])},
  "supportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Teams"])},
  "removeTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned team"])},
  "editMembersListDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is the edit mode of the ", _interpolate(_named("name")), " support team. Here, you can add or delete multiples users at the same time."])},
  "quickAddTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quickly add an user"])},
  "usersAddedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " added successfully"])},
  "userListToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users list that can be add to the team."])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added"])},
  "addToTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to team"])},
  "addTeamMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add team members"])},
  "addTeamMembersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven’t added any members to this support team yet. You can start to search and add one or more users."])},
  "quickAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick add"])},
  "editSupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit support team"])},
  "editMembersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit mode"])},
  "quitEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left edit mode"])},
  "sidebar": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "tickets": {
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets Open"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets Closed"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets Templates"])},
      "incomingEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Emails"])}
    },
    "incomingEmails": {
      "unlinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink to a client"])},
      "replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replied to closed tickets"])}
    },
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "tools": {
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "sftpAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Accounts"])}
    },
    "survey": {
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])},
      "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Surveys"])}
    },
    "subMenu": {
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial"])},
      "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues"])}
    },
    "specialNavigation": {
      "expertService3CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Service 3CX"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "georouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geographical Routing"])},
      "callqueuegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues Groups"])},
      "callqueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues"])}
    },
    "support": {
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "ticketCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket categories"])},
      "ticketStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket statuses"])},
      "emailFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email filters"])},
      "supportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Teams"])}
    },
    "hosts": {
      "hosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosts"])},
      "hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
      "software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])},
      "infrastructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastructure"])},
      "operatingSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Systems"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types"])},
      "shells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shells"])},
      "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
      "supliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppliers"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datacenter"])},
      "hostServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
    }
  },
  "header": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchTicketPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject, created date or updated date"])},
    "searchTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a ticket"])},
    "searchInvoicePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice, date or total"])},
    "searchContactPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company, name, code or numbers"])},
    "searchSftpAccountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname or username"])},
    "searchAddMembersToSupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name or email adress"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
    "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open new ticket"])},
    "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])}
  },
  "chartTable": {
    "callType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Type"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "avgPerCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg per Call"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special"])},
    "callCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call count"])},
    "callDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call duration"])},
    "fileNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File number"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "choosePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose period"])},
    "chooseResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose resource"])},
    "resourceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource number"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
    "data": {
      "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
      "unanswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered"])}
    }
  },
  "dashboards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
    "monthlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Dashboard of"])},
    "monthlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get monthly data of"])},
    "dailyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Dashboard of"])},
    "dailyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get daily data of"])},
    "hourlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Dashboard of"])},
    "hourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get hourly data of"])},
    "donutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Answered & Unanswered Calls Count"])},
    "stackedColumnCategories": {
      "incomingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Count"])},
      "outgoingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Count"])},
      "answeredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered Count"])},
      "unansweredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered Count"])},
      "incomingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Duration"])},
      "outgoingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Duration"])},
      "answeredDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered Duration"])}
    },
    "error": {
      "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
      "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data for this period was not found or don't exist yet, we're sorry for the inconvenience."])}
    }
  },
  "warning": {
    "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
    "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No call queue created yet. Please add one by clicking on 'New call queue' button."])},
    "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No call queue group created yet. Please add one by clicking on 'New call queue group' button."])},
    "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on 'Add New Time Slot' button."])},
    "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons."])}
  },
  "pbxExtension": {
    "selectData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Data"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Period"])}
  },
  "home": {
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])}
  },
  "ticket": {
    "concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concerns"])},
    "editTicketConfirmMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket updated successfully"])},
    "ticketForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Form"])},
    "typeDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type down your questions/problems below"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "supportContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support contracts"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priority"])},
    "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resolution"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Category"])},
    "selectProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Problem"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "emailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email subject"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "noLicence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No licence"])},
    "noLicenceDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They is no licence for the folder"])},
    "typeAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the problem above"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "writeReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reply for the ticket"])},
    "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template created successfully"])},
    "deleteTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete template"])},
    "deleteTemplateAskConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the template?"])},
    "deleteTemplateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template deleted successfully"])},
    "header": {
      "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open tickets"])},
      "ticketsforTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "ticketsForTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "ticketsUnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets unassigned"])},
      "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tickets"])},
      "teamTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team tickets"])}
    },
    "sendToContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to contact"])},
    "sendToContactsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose to send the reply to the client"])},
    "sendToAssignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to assignee"])},
    "sendToAssigneeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose to send the reply as a note to the assignee agent"])},
    "sendAsInternalReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reply will be sent as internal note"])},
    "copyToSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add sales on copy"])},
    "copyToSalesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The response will also be CC'd to the sales representative"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close ticket"])},
    "closeTicketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send and close the ticket"])},
    "templateAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an available ticket template"])},
    "assignedContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned contacts"])},
    "addAssignedContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add associate contact"])}
  },
  "invoiceTable": {
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice ID"])},
    "invoiceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Number"])},
    "totalWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Without Tax"])},
    "totalWithTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total With Tax"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "searchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search ID"])},
    "searchNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "searchMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "searchYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY"])},
    "searchAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search amount"])}
  },
  "ticketTable": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support team"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategory"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select rows"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
    "confirmationCloseMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close the ticket?"])},
    "confirmationOpenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reopen the ticket?"])},
    "closeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket closed successfully"])},
    "openMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket opened successfully"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action successful"])},
    "dialogTitleClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close ticket"])},
    "dialogTitleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open ticket"])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "searchStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search status"])},
    "searchDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])}
  },
  "replySurveyTable": {
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])},
    "survey_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])},
    "sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent at"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "sftpAccountTable": {
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last update"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])}
  },
  "documentTable": {
    "allDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All documents"])},
    "yourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your documents"])}
  },
  "dialog": {
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
  },
  "profile": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal details"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Token"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])}
  },
  "contacts": {
    "searchAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search account"])},
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])},
    "searchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "searchNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "contactAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact added successfully"])},
    "contactDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact deleted successfully"])}
  },
  "hostsTable": {
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
    "infrastructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infrastructure"])},
    "shell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shell"])}
  },
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "actionId": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start the call"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has started the call and call finished by the source"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has started the call and call finished by the destination"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has let the user wait, then redirect to"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is redirecting the user to"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has decline the call or didn't answer"])},
    "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has left the phone ringing and did not answer"])},
    "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has refused the call. The call was automatically redirected to"])},
    "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["could not answer. The call was automatically redirected to"])},
    "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["could not answer because the destination was unavailable"])},
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the selected number does not exist or the call has failed"])},
    "408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dont know about this action_id 408"])},
    "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has left the phone ringing and went into busy mode"])},
    "418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has refused the call or the source cancel the call"])}
  },
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
  "callAnalyser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Analyser"])},
  "callLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Logs"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear"])},
  "startAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
  "redirectAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirect at"])},
  "answeredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["answered at"])},
  "finishAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finish at"])},
  "declineAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["decline at"])},
  "initializeAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initialize at"])}
}