<template>
  <div class="flex justify-between inline-flex items-center gap-5">
    <v-date-picker
      class="inline-block"
      color="teal"
      v-model="date"
      :max-date="new Date()"
      mode="date"
    >
      <template v-slot="{ togglePopover }">
        <button
          class="encom_primary text-white capitalize rounded items-center w-40 py-3 encom_primary_border text-sm font-medium encom_primary_hover focus:z-10 mt-1"
          @click="togglePopover(), (buttonSelected = 'day')"
        >
          Day
        </button>
      </template>
    </v-date-picker>
    <div class="content-center bg-gray-50 border border-gray-300 rounded-md">
      <span class="ml-5 text-sm capitalize">
        {{ date.toLocaleDateString($i18n.locale, dateOption) }}
      </span>
      <div class="flex p-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-gray-500 mr-1 mt-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <select
          name="time-select"
          id="time-select"
          v-model="hour"
          class="mr-1 shadow-sm encom_primary_border_focus block sm:text-sm border-gray-300 rounded-md"
        >
          <option v-for="hour in hours" :key="hour" :value="hour">
            {{ hour }}
          </option>
        </select>
        <button
          class="py-0.5 px-2 bg-gray-200 rounded-md text-xs text-gray-800 hover:bg-gray-400 hover:text-gray-900"
          @click="hour = ''"
        >
          {{ $t("clear") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const date = new Date();
export default {
  data() {
    return {
      hour: "",
      date,
      dateOption: {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      buttonSelected: "",
      hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    };
  },
  mounted() {
    this.$emit("timeSelected", this.date, this.hour);
  },
  watch: {
    date: function(val) {
      this.$emit("timeSelected", val, this.hour);
    },
    hour: function(val) {
      let spliceHour = val.slice(0, 2);
      this.$emit("timeSelected", this.date, spliceHour);
    },
  },
};
</script>

<style></style>
