<template>
  <div
    class="z-10 top-0 sticky body__header border-t border-b ticket_table_heading"
  >
    <div class="sm:items-center top-0 flex flex-wrap justify-between">
      <div class="flex flex-wrap items-baseline">
        <h2 class="text-xl leading-6 font-medium text-gray-900">
          {{ this.title }}
        </h2>
        <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
          {{ this.subtitle }}
        </p>
      </div>

      <div class="inline-flex">
        <div class="flex-1 ml-2 mt-3 xs:mt-0 lg:mt-0">
          <PeriodSelector
            @timeSelected="(...args) => $emit('timeSelected', ...args)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchFilter from "../SearchFilter.vue";
import PeriodSelector from "../PBX/PeriodSelector.vue";

export default {
  name: "Header",
  props: ["title", "subtitle"],
  components: {
    PeriodSelector,
    SearchFilter,
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style></style>
