<template>
  <div class="relative">
    <select
      v-on:change="setLanguage($event)"
      v-model="$i18n.locale"
      class="block w-auto pr-auto text-white dark:text-slate-800 border-transparent focus:outline-none sm:text-sm bg-transparent"
    >
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang.toLowerCase()"
        class="bg-gray-500"
      >
        {{ lang }}
      </option>
    </select>
    <div
      class="pointer-events-none absolute inset-y-0 right-9 flex items-center"
    >
      <span v-if="$i18n.locale === 'en'">🇬🇧</span>

      <span v-if="$i18n.locale === 'fr'">🇫🇷</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  data() {
    return {
      langs: ["EN", "FR"],
      /*  img: {
        en: "https://www.countryflags.io/gb/shiny/32.png",
        fr: "https://www.countryflags.io/fr/shiny/32.png",
      }, */
    };
  },
  methods: {
    setLanguage(event) {
      this.$store.dispatch("language", event.target.value);
      localStorage.setItem("language", event.target.value);
    },
  },
};
</script>

<style></style>
