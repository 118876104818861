<template>
 <!--
    When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars
    Menu open: "fixed inset-0 z-40 overflow-y-auto", Menu closed: ""
    -->

 <div
  class="fixed top-0 hidden w-20 sidebar overflow-y-auto md:block dark:border-gray-600 dark:bg-slate-600"
 >
  <div class="w-full pb-6 flex flex-col items-center gap-2">
   <div class="flex-shrink-0 flex items-center py-2">
    <router-link to="/"><img class="h-10 w-auto" :src="logo" alt="Welcome" /></router-link>
   </div>
   <div v-for="item in navigation" :key="item.name" class="flex-1 my- w-full px-2 space-y-1">
    <router-link
     v-if="modules[item.modules]"
     :to="item.hasSideNav ? '' : item.href"
     v-show="item.show"
     :class="[
      item.href === this.$route.path
       ? `sidebar__menu--active sidebar__text dark:bg-slate-500`
       : `sidebar__menu--hover sidebar__text--hover sidebar__text dark:hover:bg-slate-500 dark:hover:text-white`,
      `group w-full p-3 rounded-md flex flex-col items-center text-xl font-medium truncate`,
     ]"
     :aria-current="item.current ? 'page' : undefined"
     @click="$emit('openSideNavHover', item.name), this.$router.push(`${item.href}`)"
    >
     <font-awesome-icon :icon="item.icon" class="icon alt" />
     <span class="mt-2 capitalize text-xs">{{ $t(`${item.name}`) }}</span>
    </router-link>
   </div>
   <div class="flex-1 w-full px-2 space-y-1">
    <router-link
     v-for="item in specialNavigation"
     :key="item.name"
     :to="item.hasSideNav ? '' : item.href"
     v-show="item.show"
     :class="[
      item.href === this.$route.path
       ? `sidebar__menu--active sidebar__text dark:bg-slate-500`
       : `sidebar__menu--hover sidebar__text sidebar__text--hover dark:hover:bg-slate-500 dark:hover:text-white`,
      `group w-full p-3 rounded-md flex flex-col items-center text-xl font-medium truncate`,
     ]"
     :aria-current="item.current ? 'page' : undefined"
     @click="$emit('openSideNavHover', item.name), this.$router.push(`${item.href}`)"
    >
     <font-awesome-icon :icon="item.icon" class="icon alt" />
     <span class="mt-2 capitalize text-xs">{{ $t(`${item.name}`) }}</span>
    </router-link>
   </div>
  </div>
  <div class="sandbox_view mx-0 w-full flex-shrink-0 border-t border-gray-200 pt-1 px-2">
   <p class="font-medium sidebar__text"><span>&copy;</span> CX-Engine</p>
   <p class="font-medium sidebar__text capitalize">
    ({{ sandbox === "true" ? $t("bannerSandbox") : $t("bannerProduction") }})
   </p>
  </div>
 </div>

 <!-- Mobile menu -->
 <TransitionRoot as="template" :show="mobileMenuOpen">
  <Dialog as="div" class="md:hidden" @close="mobileMenuOpen = false">
   <div class="fixed inset-0 z-40 flex">
    <TransitionChild
     as="template"
     enter="transition-opacity ease-linear duration-300"
     enter-from="opacity-0"
     enter-to="opacity-100"
     leave="transition-opacity ease-linear duration-300"
     leave-from="opacity-100"
     leave-to="opacity-0"
    >
     <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
    </TransitionChild>
    <TransitionChild
     as="template"
     enter="transition ease-in-out duration-300 transform"
     enter-from="-translate-x-full"
     enter-to="translate-x-0"
     leave="transition ease-in-out duration-300 transform"
     leave-from="translate-x-0"
     leave-to="-translate-x-full"
    >
     <div class="relative max-w-xs w-full sidebar pt-5 pb-4 flex-1 flex flex-col">
      <TransitionChild
       as="template"
       enter="ease-in-out duration-300"
       enter-from="opacity-0"
       enter-to="opacity-100"
       leave="ease-in-out duration-300"
       leave-from="opacity-100"
       leave-to="opacity-0"
      >
       <div class="absolute top-1 right-0 -mr-14 p-1">
        <button
         type="button"
         class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
         @click="$emit('closeSubmenu')"
        >
         <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
         <span class="sr-only">Close sidebar</span>
        </button>
       </div>
      </TransitionChild>
      <div class="flex-shrink-0 px-4 flex items-center">
       <img class="h-8 w-auto" :src="logo" alt="Workflow" />
      </div>
      <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
       <nav aria-label="Sidebar" :class="`sticky top-4 divide-malachite-light`">
        <template v-for="item in navigation" :key="item.name">
         <div v-if="!item.children" v-show="item.show !== 'false'">
          <div class="pb-2 space-y-1">
           <router-link
            v-show="item.href"
            :to="item.href"
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active sidebar__text`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group flex items-center px-2 py-2 text-sm leading-6 font-medium `,
            ]"
            :aria-current="item.current ? 'page' : undefined"
           >
            <font-awesome-icon :icon="item.icon" class="icon alt" />
            <span class="capitalize ml-2">{{ $t(`${item.name}`) }}</span>
           </router-link>
           <a
            v-show="!item.href"
            href="#"
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active sidebar__text`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group flex items-center px-2 py-2 text-sm leading-6 font-medium `,
            ]"
           >
            <font-awesome-icon :icon="item.icon" class="icon alt" />
            <span class="ml-2 capitalize">{{ $t(`${item.name}`) }}</span>
           </a>
          </div>
         </div>
         <Disclosure
          v-show="item.show !== 'false'"
          as="div"
          v-else
          class="space-y-1 pb-2"
          v-slot="{ open }"
         >
          <router-link
           :to="item.href"
           :class="[
            item.href === this.$route.path
             ? `sidebar__menu--active sidebar__text`
             : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
            ` flex items-center px-0 py-0 text-sm leading-6 font-medium `,
           ]"
           :aria-current="item.current ? 'page' : undefined"
          >
           <DisclosureButton
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active sidebar__text`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group w-full flex items-center px-2 py-2 text-left text-sm leading-6 font-medium `,
            ]"
           >
            <font-awesome-icon :icon="item.icon" class="icon alt" />
            <span class="flex-1 capitalize ml-2">
             {{ $t(`${item.name}`) }}
            </span>
            <svg
             :class="[
              open ? 'text-gray-400 rotate-90' : 'text-gray-300',
              'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
             ]"
             viewBox="0 0 20 20"
             aria-hidden="true"
            >
             <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
           </DisclosureButton>
          </router-link>

          <DisclosurePanel class="space-y-1">
           <router-link
            v-for="subItem in item.children"
            :key="subItem.name"
            :to="subItem.href"
            :class="[
             subItem.href === this.$route.path
              ? `sidebar__menu--active sidebar__text`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             `group flex items-center py-1 text-sm leading-6 font-medium `,
            ]"
           >
            <span class="px-3">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
             >
              <path
               fill-rule="evenodd"
               d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
               clip-rule="evenodd"
              />
             </svg>
            </span>
            <span class="px-0 capitalize">{{ $t(`${subItem.name}`) }}</span>
           </router-link>
          </DisclosurePanel>
         </Disclosure>
        </template>
        <div class="pb-5" v-show="this.showSpecialNavigation">
         <template v-for="item in specialNavigation" :key="item.name">
          <div v-if="!item.children">
           <div class="pb-2 space-y-1">
            <router-link
             v-show="item.href"
             :to="item.href"
             :class="[
              item.href === this.$route.path
               ? `sidebar__menu--active sidebar__text`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group flex items-center px-2 py-2 text-sm leading-6 font-medium`,
             ]"
             :aria-current="item.current ? 'page' : undefined"
            >
             <!-- <component
                  :is="item.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->
             <span class="capitalize">
              {{ $t(`${item.name}`) }}
             </span>
            </router-link>
            <a
             v-show="!item.href"
             href="#"
             :class="[
              item.href === this.$route.path
               ? `sidebar__menu--active sidebar__text`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group flex items-center px-2 py-2 text-sm leading-6 font-medium `,
             ]"
            >
             <!-- <component
                  :is="item.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->
             <span> {{ $t(`${item.name}`) }} </span>
            </a>
           </div>
          </div>
          <Disclosure as="div" v-else class="space-y-1 pb-2" v-slot="{ open }">
           <router-link
            :to="item.href"
            :class="[
             item.href === this.$route.path
              ? `sidebar__menu--active sidebar__text`
              : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
             ` flex items-center px-0 py-0 text-sm leading-6 font-medium `,
            ]"
            :aria-current="item.current ? 'page' : undefined"
           >
            <DisclosureButton
             :class="[
              item.href === this.$route.path
               ? `sidebar__menu--active sidebar__text`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group w-full flex items-center px-2 py-2 text-left text-sm leading-6 font-medium `,
             ]"
            >
             <!-- <component
                  :is="item.icon"
                  :class="[`mr-4 flex-shrink-0 h-6 w-6`]"
                  aria-hidden="true"
                /> -->

             <span class="flex-1 uppercase">
              {{ $t(`${item.name}`) }}
             </span>
             <svg
              :class="[
               open ? 'text-gray-400 rotate-90' : 'text-gray-300',
               'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
              ]"
              viewBox="0 0 20 20"
              aria-hidden="true"
             >
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
             </svg>
            </DisclosureButton>
           </router-link>

           <DisclosurePanel class="space-y-1">
            <router-link
             v-for="subItem in item.children"
             :key="subItem.name"
             :to="subItem.href"
             :class="[
              subItem.href === this.$route.path
               ? `sidebar__menu--active sidebar__text`
               : `sidebar__menu--hover sidebar__text--hover sidebar__text`,
              `group flex items-center py-1 text-sm leading-6 font-medium `,
             ]"
            >
             <span class="px-3">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               class="h-5 w-5"
               viewBox="0 0 20 20"
               fill="currentColor"
              >
               <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
               />
              </svg>
             </span>
             <span class="px-0 capitalize">{{ $t(`${subItem.name}`) }}</span>
            </router-link>
           </DisclosurePanel>
          </Disclosure>
         </template>
        </div>
        <div class="pt-0">
         <div class="mt-0 space-y-2" aria-labelledby="communities-headline">
          <a
           v-for="item in secondaryNavigation"
           :key="item.name"
           :href="item.href"
           :class="[
            `sidebar__menu--active_text sidebar__menu--hover group flex items-center px-2 py-2 text-sm leading-6 font-medium  sidebar__text--hover`,
           ]"
          >
           {{ $t(`${item.name}`) }}
          </a>
         </div>
        </div>
       </nav>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-2">
       <a
        @click="signOut()"
        href="#"
        class="mt-1 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mr-3 sm:w-auto sm:text-sm"
       >
        {{ $t("header.logout") }}
       </a>
      </div>
     </div>
    </TransitionChild>
    <div class="flex-shrink-0 w-14" aria-hidden="true">
     <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
   </div>
  </Dialog>
 </TransitionRoot>
</template>

<script>
import { HomeIcon, ClockIcon, MailIcon, XIcon } from "@heroicons/vue/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {
 Dialog,
 DialogOverlay,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { mapGetters } from "vuex";
const sandbox = localStorage.getItem("sandbox");
const logo = localStorage.getItem("logo");
export default {
 name: "Sidebar",
 props: [
  "navigation",
  "secondaryNavigation",
  "specialNavigation",
  "showSpecialNavigation",
  "statisticsAvailableForProduction",
  "hasChildren",
  "openSubmenu",
 ],
 components: {
  HomeIcon,
  ClockIcon,
  MailIcon,
  XIcon,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
 },
 data() {
  return {
   sandbox,
   mobileMenuOpen: false,
   logo,
  };
 },
 methods: {
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("userId");
   this.$store.dispatch("user", {});
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
 },
 watch: {
  openSubmenu: function (val) {
   this.mobileMenuOpen = val;
  },
 },
 computed: {
  ...mapGetters(["modules"]),
 },
};
</script>

<style>
.sandbox_view {
 position: absolute;
 bottom: 0;
 font-size: 0.65rem;
}
</style>
