import { createStore } from "vuex";
import { VueCookieNext } from "vue-cookie-next";

export default createStore({
 state: {
  user: JSON.parse(localStorage.getItem("user")) || {},
  isAuthenticated: false,
  avatar: localStorage.getItem("avatar") || "",
  ticketingStatsPeriod:
   JSON.parse(localStorage.getItem("ticketingStatsDateRange")) || "",
  isListShow: JSON.parse(localStorage.getItem("isListShow")) || false,
  token: localStorage.getItem("token") || null,
  language: localStorage.getItem("language") || "en",
  background: localStorage.getItem("background") || null,
  ticketCount: {},
  logo: localStorage.getItem("logo") || null,
  userId: localStorage.getItem("userId") || null,
  support_teams: JSON.parse(localStorage.getItem("support_teams")) || [],
  admin: localStorage.getItem("admin") || 0,
  role: localStorage.getItem("role") || 0,
  ticketTableHeader: JSON.parse(
   localStorage.getItem("tableHeadersStorage")
  ) || [
   { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
   { name: "ticketTable.client", isSort: false, show: true },
   {
    name: "ticketTable.status",
    sort: "status_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.priority",
    sort: "priority_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.category",
    sort: "ticket_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.subcategory",
    sort: "ticket_sub_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.updatedDate",
    sort: "updated_at",
    isSort: false,
    show: true,
   },
   {
    name: "resDateShort",
    sort: "estimatedResolutionDate",
    isSort: false,
    show: true,
   },
   /* {
    name: "ticketTable.createdDate",
    sort: "created_at",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.updatedDate",
    sort: "updated_at",
    isSort: false,
    show: true,
   },
   { name: "ticketTable.team", sort: "id", isSort: false, show: true }, */
   { name: "assign", show: true },
   { name: "captureFree", show: true },
  ],
  ticketTableHeadersSelect: JSON.parse(
   localStorage.getItem("tableHeadersSelectStorage")
  ) || [
   { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
   { name: "ticketTable.client", isSort: false, show: true },
   {
    name: "ticketTable.status",
    sort: "status_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.priority",
    sort: "priority_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.category",
    sort: "ticket_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.subcategory",
    sort: "ticket_sub_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.updatedDate",
    sort: "updated_at",
    isSort: false,
    show: true,
   },
   {
    name: "resDateShort",
    sort: "estimatedResolutionDate",
    isSort: false,
    show: true,
   },
   /* {
     name: "ticketTable.createdDate",
     sort: "created_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticketTable.updatedDate",
     sort: "updated_at",
     isSort: false,
     show: true,
    },
    { name: "ticketTable.team", sort: "id", isSort: false, show: true }, */
   { name: "assign", show: true },
   { name: "captureFree", show: true },
  ],
  openRemovedHeaders:
   JSON.parse(localStorage.getItem("removedHeadersStorage")) || [],
  closedRemovedHeaders:
   JSON.parse(localStorage.getItem("closedRemovedHeadersStorage")) || [],
  closedTicketTableHeader: localStorage.getItem(
   "closedTicketTableHeaderStorage"
  ) || [
   { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
   { name: "ticketTable.client", isSort: false, show: true },
   {
    name: "ticketTable.status",
    sort: "status_id",
    isSort: false,
    show: false,
   },
   {
    name: "ticketTable.priority",
    sort: "priority_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.category",
    sort: "ticket_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.subcategory",
    sort: "ticket_sub_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.updatedDate",
    sort: "updated_at",
    isSort: false,
    show: true,
   },
   {
    name: "resDateShort",
    sort: "estimatedResolutionDate",
    isSort: false,
    show: true,
   },
   /* {
     name: "ticketTable.createdDate",
     sort: "created_at",
     isSort: false,
     show: true,
    },
    {
     name: "ticketTable.updatedDate",
     sort: "updated_at",
     isSort: false,
     show: true,
    },
    { name: "ticketTable.team", sort: "id", isSort: false, show: true }, */
   { name: "assign", show: true },
   { name: "captureFree", show: true },
  ],
  closedTicketTableHeaderSelect: localStorage.getItem(
   "closedTicketTableHeaderSelectStorage"
  ) || [
   { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
   { name: "ticketTable.client", isSort: false, show: true },
   {
    name: "ticketTable.status",
    sort: "status_id",
    isSort: false,
    show: false,
   },
   {
    name: "ticketTable.priority",
    sort: "priority_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.category",
    sort: "ticket_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.subcategory",
    sort: "ticket_sub_category_id",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.updatedDate",
    sort: "updated_at",
    isSort: false,
    show: true,
   },
   {
    name: "resDateShort",
    sort: "estimatedResolutionDate",
    isSort: false,
    show: true,
   },
   /* {
    name: "ticketTable.createdDate",
    sort: "created_at",
    isSort: false,
    show: true,
   },
   {
    name: "ticketTable.updatedDate",
    sort: "updated_at",
    isSort: false,
    show: true,
   },
   { name: "ticketTable.team", sort: "id", isSort: false, show: true }, */
   { name: "assign", show: true },
   { name: "captureFree", show: true },
  ],
  dark: localStorage.getItem("theme") === "dark" ? true : false,
  modules: localStorage.getItem("modules")
   ? JSON.parse(localStorage.getItem("modules"))
   : {},
  ticketExchangeAlert: localStorage.getItem("ticketExchangeAlert")
   ? localStorage.getItem("ticketExchangeAlert")
   : 7,
 },
 mutations: {
  user(state, user) {
   state.user = user;
  },
  isAuthenticated(state, isAuthenticated) {
   state.isAuthenticated = isAuthenticated;
  },
  isListShow(state, isListShow) {
   state.isListShow = isListShow;
  },
  avatar(state, avatar) {
   state.avatar = avatar;
  },
  token(state, token) {
   state.token = token;
  },
  language(state, language) {
   state.language = language;
  },
  logo(state, logo) {
   state.logo = logo;
  },
  userId(state, userId) {
   state.userId = userId;
  },
  support_teams(state, support_teams) {
   state.support_teams = support_teams;
  },
  admin(state, admin) {
   state.admin = admin;
  },
  role(state, role) {
   state.role = role;
  },
  ticketTableHeadersSelect(state, ticketTableHeadersSelect) {
   state.ticketTableHeadersSelect = ticketTableHeadersSelect;
  },
  openRemovedHeaders(state, openRemovedHeaders) {
   state.openRemovedHeaders = openRemovedHeaders;
  },
  ticketTableHeader(state, ticketTableHeader) {
   state.ticketTableHeader = ticketTableHeader;
  },
  closedTicketTableHeader(state, closedTicketTableHeader) {
   state.closedTicketTableHeader = closedTicketTableHeader;
  },
  closedTicketTableHeaderSelect(state, closedTicketTableHeaderSelect) {
   state.closedTicketTableHeaderSelect = closedTicketTableHeaderSelect;
  },
  closedRemovedHeaders(state, closedRemovedHeaders) {
   state.closedRemovedHeaders = closedRemovedHeaders;
  },
  background(state, background) {
   state.background = background;
  },
  ticketingStatsPeriod(state, ticketingStatsPeriod) {
   state.ticketingStatsPeriod = ticketingStatsPeriod;
  },
  dark(state, dark) {
   state.dark = dark;
  },
  modules(state, modules) {
   state.modules = modules;
  },
  ticketExchangeAlert(state, ticketExchangeAlert) {
   state.ticketExchangeAlert = ticketExchangeAlert;
  },
 },
 actions: {
  isAuthenticated(context, isAuthenticated) {
   context.commit("isAuthenticated", isAuthenticated);
  },
  isListShow(context, isListShow) {
   context.commit("isListShow", isListShow);
  },
  user(context, user) {
   context.commit("user", user);
  },
  userAvatar(context, avatar) {
   context.commit("avatar", avatar);
  },
  token(context, token) {
   context.commit("token", token);
  },
  language(context, language) {
   context.commit("language", language);
  },
  logo(context, logo) {
   context.commit("logo", logo);
  },
  userId(context, userId) {
   context.commit("userId", userId);
  },
  admin(context, admin) {
   context.commit("admin", admin);
  },
  role(context, role) {
   context.commit("role", role);
  },
  support_teams(context, support_teams) {
   context.commit("support_teams", support_teams);
  },
  ticketTableHeadersSelect(context, ticketTableHeadersSelect) {
   context.commit("ticketTableHeadersSelect", ticketTableHeadersSelect);
  },
  openRemovedHeaders(context, openRemovedHeaders) {
   context.commit("openRemovedHeaders", openRemovedHeaders);
  },
  ticketTableHeader(context, ticketTableHeader) {
   context.commit("ticketTableHeader", ticketTableHeader);
  },
  closedTicketTableHeader(context, closedTicketTableHeader) {
   context.commit("closedTicketTableHeader", closedTicketTableHeader);
  },
  closedTicketTableHeaderSelect(context, closedTicketTableHeaderSelect) {
   context.commit(
    "closedTicketTableHeaderSelect",
    closedTicketTableHeaderSelect
   );
  },
  closedRemovedHeaders(context, closedRemovedHeaders) {
   context.commit("closedRemovedHeaders", closedRemovedHeaders);
  },
  background(context, background) {
   context.commit("background", background);
  },
  ticketingStatsPeriod(context, ticketingStatsPeriod) {
   context.commit("ticketingStatsPeriod", ticketingStatsPeriod);
  },
  dark(context, dark) {
   context.commit("dark", dark);
  },
  modules(context, modules) {
   context.commit("modules", modules);
  },
  ticketExchangeAlert(context, ticketExchangeAlert) {
   context.commit("ticketExchangeAlert", ticketExchangeAlert);
  },
 },
 getters: {
  user: state => {
   return state.user;
  },
  isAuthenticated: state => {
   return state.isAuthenticated;
  },
  isListShow: state => {
   return state.isListShow;
  },
  avatar: state => {
   return state.user;
  },
  token: state => {
   return state.token;
  },
  language: state => {
   return state.language;
  },
  logo: state => {
   return state.logo;
  },
  userId: state => {
   return state.userId;
  },
  admin: state => {
   return state.admin;
  },
  role: state => {
   return state.role;
  },
  support_teams: state => {
   return state.support_teams;
  },
  openRemovedHeaders: state => {
   return state.openRemovedHeaders;
  },
  ticketTableHeadersSelect: state => {
   return state.ticketTableHeadersSelect;
  },
  ticketTableHeader: state => {
   return state.ticketTableHeader;
  },
  closedTicketTableHeader: state => {
   return state.closedTicketTableHeader;
  },
  closedTicketTableHeaderSelect: state => {
   return state.closedTicketTableHeaderSelect;
  },
  closedRemovedHeaders: state => {
   return state.closedRemovedHeaders;
  },
  background: state => {
   return state.background;
  },
  ticketingStatsPeriod: state => {
   return state.ticketingStatsPeriod;
  },
  dark: state => {
   return state.dark;
  },
  modules: state => {
   return state.modules;
  },
  ticketExchangeAlert: state => {
   return state.ticketExchangeAlert;
  },
 },
});
