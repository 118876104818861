<template>
 <div class="w-full">
  <div class="relative">
   <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30">
    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
   </div>
   <vue-select
    v-model="search"
    :options="data ? data : quickData"
    :label-by="labelBy ? labelBy : 'label'"
    searchable
    clear-on-select
    clear-on-close
    :loading="isLoading"
    :placeholder="selectedCustomer ? selectedCustomer : placeholder"
    @search:input="handleSearchInput"
    :maxHeight="maxHeight ? maxHeight : ''"
    :class="[
     incomingEmail
      ? 'rounded-none rounded-l-md border border-gray-300 placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400'
      : isUnfinished && incomingEmail
      ? 'border-red-300 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
      : 'rounded-md border border-gray-300 placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400',
     'block w-full bg-white py-1 pl-10 pr-3 text-sm encom_primary_border_focus sm:text-sm z-20',
    ]"
   >
   </vue-select>
   <div
    v-show="isUnfinished && incomingEmail"
    class="pointer-events-none absolute inset-y-0 right-0 pr-10 flex items-center z-30"
   >
    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";

import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { SearchIcon } from "@heroicons/vue/outline";

export default {
 props: [
  "placeholder",
  "selectedCustomer",
  "searchMethod",
  "data",
  "incomingEmail",
  "isUnfinished",
  "labelBy",
  "maxHeight",
 ],
 components: {
  VueSelect,
  SearchIcon,
  ExclamationCircleIcon,
 },
 data() {
  return {
   quickData: [],
   search: "",
   searchableTerm: "",
   isLoading: false,
  };
 },
 methods: {
  handleSearchInput(event) {
   this.searchableTerm = event.target.value;
  },
  async getQuick(term) {
   try {
    if (term) {
     this.isLoading = true;

     const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`);
     this.quickData = res.data;
     this.isLoading = false;
    }
   } catch (error) {
    this.isLoading = false;
    this.errorHandling(error);
   }
  },
 },
 watch: {
  searchableTerm: function(val) {
   console.log("file: QuickSearch.vue ~ line 90 ~ val", val);
   if (this.searchMethod) {
    console.log(val);
    if (val.length > 2) {
     this.searchMethod(val);
    }
   } else {
    if (val.length > 3) this.getQuick(val);
   }
  },
  search: function(val) {
   console.log("file: QuickSearch.vue ~ line 101 ~ val", val);
   this.isLoading = false;
   this.$emit("action", val);
   if (this.search) {
    this.search.length = 0;
   }
  },
 },
};
</script>

<style></style>
