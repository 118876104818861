<template>
 <div
  aria-live="assertive"
  class="z-50 fixed inset-x-0 inset-y-10 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
 >
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
   <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->

   <div
    v-show="active && closeTimer"
    class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
   >
    <div class="p-4">
     <div class="flex items-start">
      <div v-if="active && isSuccess && closeTimer" class="flex-shrink-0">
       <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
      </div>
      <div v-else-if="active && !isSuccess && closeTimer" class="flex-shrink-0">
       <XCircleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div class="ml-3 w-0 flex-1 pt-0.5">
       <p v-if="typeof msg === 'string'" :class="['font-medium text-gray-900 text-sm']">
        {{ msg }}
       </p>
       <p v-else :class="['font-medium text-gray-900 text-sm']">
        <span v-for="e in msg">{{ e }}</span>
       </p>
       <li v-else v-for="e in msg" class="font-medium text-gray-900 text-sm list-none">
        <ul>
         {{
          e
         }}
        </ul>
       </li>
      </div>
      <div class="ml-4 flex-shrink-0 flex">
       <button
        @click="$emit('closeBanner')"
        class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
       >
        <span class="sr-only">Close</span>
        <XIcon class="h-5 w-5" aria-hidden="true" />
       </button>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/outline";

export default {
 props: ["msg", "active", "isSuccess"],
 data() {
  return {
   closeTimer: false,
  };
 },
 components: {
  XIcon,
  XCircleIcon,
  CheckCircleIcon,
 },
 methods: {
  openBanner() {
   this.closeTimer = true;
   let v = this;
   setTimeout(function () {
    v.closeTimer = false;
    v.$emit("bannerTimer");
    v.$forceUpdate();
   }, 3000);
  },
 },
 mounted() {
  this.openBanner();
 },
};
</script>

<style>
.banner_min_height {
 height: 25px;
}
</style>
