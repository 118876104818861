<template>
 <header :class="[dark ? 'dark__header' : 'header', 'fixed top-0 z-30 w-full bg-white shadow']">
  <div class="py-1.5 pr-4 pl-4 md:pl-28 sm:pr-6 lg:pr-8">
   <div class="flex justify-between main_page_header">
    <div class="flex">
     <div class="flex items-center mr-2 -ml-2 md:hidden">
      <!-- Mobile menu button -->
      <button
       @click="$emit('openSubmenu')"
       class="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:encom_border_input"
      >
       <span class="sr-only">Open main menu</span>
       <MenuIcon v-if="!open" class="block w-6 h-6" aria-hidden="true" />
       <XIcon v-else class="block w-6 h-6" aria-hidden="true" />
      </button>
     </div>
    </div>

    <div class="flex-1">
     <div class="items-center max-w-xs md:max-w-2xl flex">
      <div class="w-full">
       <MainHeaderSearch @setLoading="$emit('setLoading', $event)" />
      </div>
      <div class="pt-0.5 md:pt-0 ml-2">
       <button
        @click="this.$router.push(`/customer`)"
        type="button"
        class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm header__button"
       >
        <!-- <PlusSmIcon class="w-5 h-5 mr-2 -ml-1" aria-hidden="true" /> -->
        <span
         ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          viewBox="0 0 20 20"
          fill="currentColor"
         >
          <path
           d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
          /></svg
        ></span>
       </button>
      </div>
     </div>
    </div>

    <div class="flex items-center">
     <div class="flex-shrink-0">
      <LanguageSelector />
     </div>
     <!-- <DarkModeToggle /> -->
     <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
      <!-- Profile dropdown -->
      <Menu as="div" class="relative">
       <div>
        <MenuButton class="flex items-center mt-1 text-sm rounded-full focus:outline-none">
         <span class="sr-only">Open user menu</span>
         <div class="flex-shrink-0">
          <img
           class="rounded-full h-9 w-9"
           :src="`${this.$cookie.getCookie('API')}/avatar/user/${userId}` + '?t=' + timestamp"
           alt="image-profile"
          />
         </div>
        </MenuButton>
       </div>
       <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
       >
        <MenuItems
         class="absolute right-0 z-30 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
         <MenuItem v-slot="{ active }">
          <a
           href="javascript:void(0)"
           @click.prevent="signOut"
           :class="[
            active ? 'bg-gray-100' : '',
            'block mx-2 rounded-md px-4 py-2 text-sm text-gray-700 z-30',
           ]"
           >{{ $t("header.logout") }}</a
          >
         </MenuItem>
        </MenuItems>
       </transition>
      </Menu>
     </div>
    </div>
   </div>
  </div>
 </header>
</template>

<script>
import DarkModeToggle from "./DarkModeToggle.vue";
import MainHeaderSearch from "./MainHeaderSearch.vue";
import QuickSearchComponent from "./QuickSearchComponent.vue";
import axios from "axios";

import {
 Disclosure,
 DisclosureButton,
 DisclosurePanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { SearchIcon, MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/vue/outline";
import { PlusIcon, PlusSmIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

import LanguageSelector from "../components/LanguageSelector.vue";
import QuickSearch from "../components/QuickSearch.vue";

const avatar = localStorage.getItem("avatar");
const logo = localStorage.getItem("logo");
const userId = localStorage.getItem("userId");
const timestamp = new Date().getTime();

export default {
 props: [
  "navigation",
  "specialNavigation",
  "showSpecialNavigation",
  "callerNumberNotFound",
  "callerNumber",
 ],
 components: {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  SearchIcon,
  MenuIcon,
  PlusIcon,
  XIcon,
  ChevronDownIcon,
  LanguageSelector,
  PlusSmIcon,
  QuickSearch,
  QuickSearchComponent,
  MainHeaderSearch,
  DarkModeToggle,
 },
 data() {
  return {
   avatar,
   search: "",
   quickData: [],
   logo,
   timestamp,
   searchableTerm: "",
   userId,
  };
 },
 methods: {
  goToDetail(event) {
   if (event) {
    let id = event.searchable_id;
    this.$router.push(`/customer/${id}`);
   }
  },
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("modules");
   // this.$cookie.removeCookie("token");
   localStorage.removeItem("userId");
   this.$store.dispatch("user", {});
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$store.dispatch("modules", {});
   this.$router.push("/login");
   location.reload();
  },
  checkAvatar() {
   return this.avatar == "";
  },
 },
 computed: {
  ...mapGetters(["user", "dark"]),
 },
};
</script>

<style>
datalist {
 display: none;
}
</style>
