<template>
  <Header
    v-model:search="search"
    :searchStatus="this.searchStatus"
    @pushStatusSearch="this.pushSearchStatus($event)"
    title="Analytics"
  />
  <div class="mr-5 ml-5 mt-1 mb-1">
    <div class="pl-10 pr-10 pt-5">
      <StatsHeading :stats="this.stats" />
      <DonutsDashboard
        :seriesTotal="totalChartSeries"
        :labelTotal="totalChartLabels"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import StatsHeading from "../components/PBX/StatsHeading.vue";
import Header from "../components/PBX/Header.vue";
import DonutsDashboard from "../components/PBX/DonutsDashboard.vue";

export default {
  name: "Home",
  components: {
    Header,
    StatsHeading,
    DonutsDashboard,
  },

  data() {
    return {
      montlyTotalSeries: [],
      montlyTotalLabels: [],
      totalChartSeries: [],
      totalChartLabels: [],
      pbx3cxData: {},
      callData: [],
      callDataHistory: [],
      callDataParticipants: [],
      callDataSegments: [],
      hostName: "demo-encom.3cx.fr",
      limit: 1,
      pbxExtensions: [],
      totalExtension: [],
      totalCallQueue: [],
      totalOther: [],
      totalExtensionValue: "",
      pbxQueues: [],
      stats: [
        {
          name: "Total Extension",
          stat: "1000",
          previousStat: "900",
          change: "10%",
          changeType: "increase",
        },
        {
          name: "Total Callqueue",
          stat: "",
          previousStat: "",
          change: "",
          changeType: "",
        },
        {
          name: "Total Other",
          stat: "",
          previousStat: "",
          change: "",
          changeType: "",
        },
      ],
    };
  },
  methods: {
    sortDateTime(data) {
      data.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });
    },
    formatDateToTime(date) {
      if (date) {
        let hours = ("0" + new Date(date).getHours()).slice(-2);
        let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
        let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "null";
      }
    },
    async getPBXData() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/pbx3cxCalls?host_name=${
            this.hostName
          }&limit=${this.limit}`
        );
        console.log(res.data);

        this.callDataHistory = res.data.history;
        this.callDataSegments = res.data.segments;
        this.callDataParticipants = res.data.participants;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getPbx3cxData() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/pbx3cx/3/origins`
        );
        this.pbx3cxData = res.data;
        let keys = Object.keys(res.data);
        let extensions = [];
        let queues = [];
        for (let index = 0; index < keys.length; index++) {
          if (keys[index].includes("extension")) {
            let spliceKey = keys[index].split(":");
            extensions.push(spliceKey[1]);
          }
          let spliceQueueKey = keys[index].split(":");
          if (spliceQueueKey[0].includes("queue")) {
            let spliceKey = keys[index].split(":");
            queues.push(spliceKey[1]);
          }
        }
        this.pbxExtensions = extensions.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        });
        this.pbxQueues = queues.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        });
        return res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getMontlyPbxDataForExtensionOrQueue(numb) {
      let data = await this.getPbx3cxData();
      let keys = Object.keys(data);
      keys.forEach((element, index) => {
        let spliceKey = keys[index].split(":");
        if (keys[index].includes("extension") && spliceKey[2].length === 6) {
          this.totalExtension.push(parseInt(data[keys[index]]));
        }
        let spliceQueueKey = keys[index].split(":");
        if (keys[index].includes("queue") && spliceQueueKey[2].length === 6) {
          this.totalCallQueue.push(parseInt(data[keys[index]]));
        }
        let spliceOtherKey = keys[index].split(":");
        if (keys[index].includes("other") && spliceOtherKey[2].length === 6) {
          this.totalOther.push(parseInt(data[keys[index]]));
        }
      });
      this.getTotal();
      // let montlyTotal = undefined;
      for (let index = 0; index < keys.length; index++) {
        let spliceQueueKey = keys[index].split(":");
        if (
          numb === spliceQueueKey[1] &&
          spliceQueueKey[2] === this.currentMonthAndYearForPbx
        ) {
          this.montlyTotal = data[keys[index]];
        }
      }
    },
    getTotal() {
      const add = (arr) => arr.reduce((a, b) => a + b, 0);
      this.stats[0].stat = add(this.totalExtension);
      this.stats[1].stat = add(this.totalCallQueue);
      this.stats[2].stat = add(this.totalOther);
      for (let index = 0; index < this.stats.length; index++) {
        this.totalChartSeries.push(this.stats[index].stat);
        this.totalChartLabels.push(this.stats[index].name);
      }
      this.stats[0].previousStat = 0;
      this.stats[1].previousStat = 0;
      this.stats[2].previousStat = 0;
      this.stats[0].change = "0%";
      this.stats[1].change = "0%";
      this.stats[2].change = "0%";
    },
  },
  mounted() {
    // this.getPBXData();
    // this.getPbx3cxData();
    this.getMontlyPbxDataForExtensionOrQueue("813");
  },
  computed: {
    currentMonthAndYearForPbx() {
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();
      return String(year) + String(month);
    },
  },
  watch: {
    limit: function(val) {
      if (val > 0) this.getPBXData();
      else this.limit = 1;
    },
  },
};
</script>

<style></style>
