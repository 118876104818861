<template>
 <Combobox as="div" v-model="selectedPerson">
  <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
  <div class="relative">
   <div class="absolute inset-y-0 left-0 flex items-center gap-2 pl-3 pointer-events-none">
    <SearchIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
    <p v-if="!hideSearchText && !selectedPerson" class="text-sm text-gray-400 capitalize">
     {{ $t("search") }}
    </p>
   </div>
   <div class="flex">
    <ComboboxInput
     class="w-full py-2 pl-10 pr-10 bg-white border border-gray-300 rounded-md shadow-sm dark:text-gray-800 sm:text-sm"
     @change="query = $event.target.value"
     :display-value="(person) => person?.label"
     @focus="hideSearchText = true"
     @blur="hideSearchText = false"
     autocomplete="off"
    />
   </div>
   <ComboboxButton
    class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
   >
    <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
   </ComboboxButton>

   <ComboboxOptions
    v-if="quickData.length > 0"
    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
   >
    <ComboboxOption
     v-for="element in quickData"
     :key="element.id"
     :value="element"
     as="template"
     v-slot="{ active, selected }"
    >
     <li
      :class="[
       'relative cursor-default select-none rounded-md mx-2 py-2 pl-3 pr-9',
       active ? 'main text-white' : 'text-gray-900',
      ]"
      @click="goToDetail(element)"
     >
      <span :class="['block truncate', selected && 'font-semibold']">
       {{ element.label }}
      </span>

      <span
       v-if="selected"
       :class="[
        'absolute inset-y-0 right-0 flex items-center pr-4',
        active ? 'text-white' : 'primary_text',
       ]"
      >
       <CheckIcon class="w-5 h-5" aria-hidden="true" />
      </span>
     </li>
    </ComboboxOption>
   </ComboboxOptions>
  </div>
 </Combobox>
</template>

<script>
import axios from "axios";
import { CheckIcon, ChevronUpDownIcon, PlusIcon, PlusSmIcon } from "@heroicons/vue/solid";
import { SearchIcon } from "@heroicons/vue/outline";
import {
 Combobox,
 ComboboxButton,
 ComboboxInput,
 ComboboxLabel,
 ComboboxOption,
 ComboboxOptions,
} from "@headlessui/vue";

export default {
 components: {
  CheckIcon,
  SearchIcon,
  PlusIcon,
  PlusSmIcon,
  ChevronUpDownIcon,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
 },
 data() {
  return {
   quickData: [],
   query: "",
   selectedPerson: null,
   search: "",
   hideSearchText: false,
   requestCount: 0,
   requestBack: 0,
  };
 },
 methods: {
  goToDetail(event) {
   if (event) {
    let id = event.searchable_id;
    this.$router.push(`/customer/${id}`);
   }
  },
  getQuick(term) {
   let termAfter = "";
   if (this.query) {
    axios
     .get(`${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${this.query}`)
     .then((res) => {
      termAfter = term;
      if (termAfter === this.query) {
       this.quickData = res.data;
      }
     })
     .catch((error) => {
      this.errorHandling(error);
     });
   }
  },
  // async getQuick(term) {
  //  this.$emit("setLoading", true);
  //  this.quickData.length = 0;
  //  try {
  //   if (term) {
  //    console.log("file: MainHeaderSearch.vue:132 ~ getQuick ~ term:", term);
  //    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`);
  //    console.log("file: MainHeaderSearch.vue:133 ~ getQuick ~ res.data:", res.data);
  //    this.quickData = res.data;
  //   }
  //   this.$emit("setLoading", false);
  //  } catch (error) {
  //   this.errorHandling(error);
  //   this.$emit("setLoading", false);
  //  }
  // },
 },
 watch: {
  query: function (val) {
   this.quickData.length = 0;
   if (val.length > 3 && this.quickData.length == 0) {
    // setTimeout(this.getQuick(val), 500);
    this.getQuick(val);
   }
  },
  selectedPerson: function (val) {
   this.goToDetail(val);
  },
  search: function (val) {
   this.$emit("action", val);
   if (this.search) {
    this.search.length = 0;
   }
  },
 },
 computed: {},
};
</script>
