<template>
 <div class="w-full relative">
  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
   <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
  </div>

  <div
   v-if="showElementList"
   class="pointer-events-none absolute inset-y-0 right-2 pl-3 flex items-center text-gray-400"
  >
   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path
     fill-rule="evenodd"
     d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
     clip-rule="evenodd"
    />
   </svg>
  </div>
  <div
   v-if="!showElementList"
   class="pointer-events-none absolute inset-y-0 right-2 pl-3 flex items-center text-gray-400"
  >
   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path
     fill-rule="evenodd"
     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
     clip-rule="evenodd"
    />
   </svg>
  </div>
  <input
   @focus="showElementList = true"
   @blur="showElementList = false"
   list="quickList"
   v-model="searchableTerm"
   :class="[
    showElementList ? 'rounded-t-md' : 'rounded-md',
    'w-full bg-white border border-gray-300 focus:border-gray-300 py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:ring-0 focus:outline-0 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm',
   ]"
   :placeholder="$t('search')"
   type="search"
   ref="mainHeaderSearch"
  />

  <transition
   enter-active-class="transition ease-out duration-100"
   enter-from-class="transform opacity-0 scale-95"
   enter-to-class="transform opacity-100 scale-100"
   leave-active-class="transition ease-in duration-75"
   leave-from-class="transform opacity-100 scale-100"
   leave-to-class="transform opacity-0 scale-95"
  >
   <div
    v-if="
     showClickableList || (showElementList && searchableTerm.length > 3 && quickData.length > 0)
    "
    @mouseover="showClickableList = true"
    @mouseleave="showClickableList = false"
    class="absolute z-999 bg-white w-full border search_element_list"
   >
    <div>
     <ul role="list" class="divide-y divide-gray-200">
      <li
       v-for="element in quickData"
       :key="element.label"
       :class="['py-1 cursor-pointer hover:bg-green-300 text-gray-700 text-xs']"
       @click="this.$emit('action', element)"
      >
       <a>{{ element.label }}</a>
      </li>
     </ul>
    </div>
   </div>
  </transition>
 </div>
</template>

<script>
import axios from "axios";
import { SearchIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
 props: [
  "searchData",
  "infiniteHandler",
  "selected",
  "urlType",
  "action",
  "slicedListIncludes",
  "elementListConcatReduce",
  "callerNumberNotFound",
  "callerNumber",
 ],
 components: {
  SearchIcon,
 },
 data() {
  return {
   quickData: [],
   search: "",
   searchableTerm: "",
   searchElement: "",
   showElementList: false,
   showClickableList: false,
  };
 },
 methods: {
  handleSearchInput(event) {
   this.searchableTerm = event.target.value;
  },
  async getQuick(term) {
   try {
    if (term) {
     this.isLoading = true;

     if (term.includes("#")) {
      term = term.replace("#", "%23");
     }

     const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`);
     this.quickData = res.data;
     console.log("file: QuickSearchComponent.vue ~ line 135 ~ getQuick ~ res.data", res.data);
     this.isLoading = false;
    }
   } catch (error) {
    this.isLoading = false;
    this.errorHandling(error);
   }
  },
 },
 computed: {
  ...mapGetters(["groupSelected", "groupSelectedName"]),
 },
 watch: {
  searchableTerm: function(val) {
   if (val.length > 3) this.getQuick(val);
  },
  search: function(val) {
   this.isLoading = false;
   this.$emit("action", val);
   if (this.search) {
    this.search.length = 0;
   }
  },
  callerNumberNotFound: function(val) {
   this.$refs.mainHeaderSearch.focus();
//    this.searchableTerm = this.callerNumber;
  },
 },
};
</script>

<style scoped>
.search_element_list {
 max-height: 15rem;
 overflow-y: auto;
}
</style>
