<template>
 <div
  v-if="navigation"
  class="flex flex-col flex-grow pt-5 pb-4 navbar overflow-y-auto dark:bg-slate-500"
 >
  <div class="flex justify-between border-b pb-1.5 border-gray-200 dark:border-slate-400">
   <h1 class="ml-1 mt-1">{{}}</h1>
   <div class="flex justify-end">
    <button
     class="p-2 hover:bg-gray-100 rounded-lg mr-1 navbar__text dark:text-white dark:hover:bg-slate-400"
     @click="$emit('showSideNav')"
    >
     <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
     >
      <path
       fill-rule="evenodd"
       d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
       clip-rule="evenodd"
      />
     </svg>
     <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg> -->
    </button>
   </div>
  </div>
  <div class="mt-6 flex-grow flex flex-col">
   <nav
    class="flex-1 px-2 space-y-1 navbar navbar__text dark:bg-slate-500 dark:text-slate-200"
    aria-label="Sidebar"
   >
    <template v-for="item in navigation" :key="item.name">
     <div v-if="!item.children">
      <router-link
       :to="item.href"
       v-if="!('show' in item) || item.show"
       :class="[
        item.href === this.$route.path
         ? 'navbar__current dark:bg-slate-600'
         : 'navbar__hover dark:hover:bg-slate-600 dark:hover:text-slate-200',
        item.number == 0 && item.href !== this.$route.path
         ? 'navbar__ligh-text dark:text-slate-400'
         : 'navbar__text dark:text-slate-200',
        'group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium rounded-md flex justify-between',
       ]"
      >
       {{ $t(`${item.name}`) }}
       <span v-if="item.number >= 0">{{ item.number }}</span>
      </router-link>
     </div>
     <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
      <DisclosureButton
       v-if="!('show' in item) || item.show"
       :class="[
        item.href === this.$route.path
         ? 'navbar__current dark:bg-slate-600'
         : 'navbar__hover navbar__text dark:hover:bg-slate-600 dark:hover:text-slate-200',
        'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none dark:text-slate-200',
       ]"
      >
       <svg
        :class="[
         open ? 'text-gray-400 rotate-90 dark:text-slate-200' : 'text-gray-500 dark:text-slate-300',
         'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 dark:group-hover:text-slate-200 transition-colors ease-in-out duration-150',
        ]"
        viewBox="0 0 20 20"
        aria-hidden="true"
       >
        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
       </svg>
       {{ $t(`${item.name}`) }}
      </DisclosureButton>
      <DisclosurePanel class="space-y-1">
       <router-link
        v-for="subItem in item.children"
        :key="subItem.name"
        :to="subItem.href"
        :class="[
         subItem.href === this.$route.path
          ? 'navbar__current dark:bg-slate-600 dark:text-slate-200'
          : 'navbar__hover navbar__text dark:hover:bg-slate-600 dark:hover:text-slate-200',
         'group w-full flex items-center pr-2 pl-10 py-2 text-left text-sm font-medium rounded-md focus:outline-none dark:text-slate-200',
        ]"
       >
        {{ $t(`${subItem.name}`) }}
        <span v-if="subItem.number >= 0">{{ subItem.number }}</span>
       </router-link>
      </DisclosurePanel>
     </Disclosure>
    </template>
   </nav>
  </div>
 </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
 props: ["navigation"],
 components: {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
 },
 data() {
  return {
   showMsg: false,
  };
 },
 methods: {
  hasNavigation() {
   if (!this.navigation) {
    this.$emit("hasNavigation", false);
   } else {
    this.$emit("hasNavigation", true);
   }
  },
 },
 mounted() {
  this.hasNavigation();
 },
 watch: {
  navigation: function () {
   this.hasNavigation();
  },
 },
};
</script>

<style></style>
