export default {
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
  "standard-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des statistiques"])},
  "statistics overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aperçu des statistiques"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "this week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
  "last week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semaine dernière"])},
  "this month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois-ci"])},
  "last month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
  "last 3 months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 3 derniers mois"])},
  "last 6 months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 6 derniers mois"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisée"])},
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
  "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
  "newNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel note"])},
  "host name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'hôtes"])},
  "expert statistics version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert statistics version"])},
  "last backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last backup"])},
  "disk free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk free"])},
  "free memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free memory"])},
  "services monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services monitoring"])},
  "replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondu"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "spellCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier orthographe"])},
  "alertTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte tickets"])},
  "expert_stats_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version des experts stats"])},
  "sbc_aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias SBC"])},
  "ssh_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès SSH"])},
  "ssh_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur SSH"])},
  "ssh_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe SSH"])},
  "ssh_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clé SSH"])},
  "database_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la BDD"])},
  "database_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur de la BDD"])},
  "database_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe BDD"])},
  "backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegardes"])},
  "backups_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement de la sauvegarde"])},
  "fetch_backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer les sauvegardes"])},
  "backups_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence de sauvegarde"])},
  "backups_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôte sauvegardé"])},
  "backups_host_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur de l'hôte"])},
  "backups_host_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe de l'hôte"])},
  "cdr_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement des CDR"])},
  "fetch_cdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer les CDR"])},
  "push_cdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les CDR"])},
  "cdr_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence des CDR"])},
  "csv_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement des logs"])},
  "recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
  "recordings_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement des enregistrements"])},
  "fetch_recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer les enregistrements"])},
  "push_recordings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les enregistrements"])},
  "recordings_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence des enregistrements"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
  "newAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel audit"])},
  "unsolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non résolu"])},
  "solved_within_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résolu pendant l'audit"])},
  "correct_before_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corriger avant l'audit"])},
  "editAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit audit"])},
  "due_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dû le"])},
  "audited_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisé le"])},
  "audited_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectué par"])},
  "technician_in_charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technicien en charge"])},
  "server_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de facturation du serveur"])},
  "server_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du serveur"])},
  "server_sizing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensionnement du serveur"])},
  "server_ssh_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation des clefs publiques"])},
  "pbx_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage de la langue"])},
  "pbx_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application des dernières mises à jour 3CX"])},
  "pbx_backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage des sauvegardes"])},
  "pbx_firewall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage du pare-feu"])},
  "pbx_in_out_bound_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage des règles entrantes et sortantes"])},
  "pbx_contacts_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage des correspondances de contacts"])},
  "pbx_user_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage des droits utilisateurs"])},
  "pbx_provider_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage spéficique à l'opérateur"])},
  "pbx_emergency_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage des numéros d'urgence"])},
  "pbx_codecs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage des codecs"])},
  "pbx_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage langue des emails"])},
  "pbx_provision_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification aléatoire de 10% des extensions"])},
  "pbx_provision_callqueues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification aléatoire de 10% des files d'attente"])},
  "next_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du prochain audit"])},
  "pbx_users_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration des extensions"])},
  "pbx_users_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration des droits par défaut des extensions"])},
  "editHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données techniques"])},
  "newHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel hôte"])},
  "pbx3cx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX 3cx"])},
  "pbxAudits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audits PBX"])},
  "pbx_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PBX Logs"])},
  "pbx3cxHosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtes PBX 3CX"])},
  "pleaseEnterValidHostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'entrer un hôte valide"])},
  "3cxTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils 3cx"])},
  "callLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log des appels"])},
  "hostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom d'hôte"])},
  "externalFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichier externe"])},
  "quizQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["question"])},
  "quizName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom du quiz"])},
  "multipleChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choix multiples"])},
  "trueOrFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vrai ou faux"])},
  "newProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau programme"])},
  "newQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau quiz"])},
  "newLesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle leçon"])},
  "newCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau cours"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cours"])},
  "programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programmes"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finir"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voir"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
  "noAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucunes pièces jointes de trouvées"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
  "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audience"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publié"])},
  "buyable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["achetable"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pric"])},
  "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extrait"])},
  "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leçons"])},
  "goTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aller à"])},
  "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété(e)"])},
  "trunkExternalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro externe du Trunk"])},
  "avgOpenedMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne ouvert"])},
  "avgClosedMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne fermé"])},
  "avgDurationMinutesMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne durée"])},
  "avgTimeMinutesMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne temps"])},
  "avgPointsPerTicketMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne points par ticket"])},
  "avgOpenedWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne ouvert"])},
  "avgClosedWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne fermé"])},
  "avgDurationMinutesWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne durée"])},
  "avgTimeMinutesWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne temps"])},
  "avgPointsPerTicketWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne points par ticket"])},
  "generalAvgStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moyenne générale"])},
  "perCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par client"])},
  "ticketingStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketing stats"])},
  "statsPerUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par utilisateur"])},
  "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temp"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
  "ticketDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date du ticket"])},
  "ticket de facturation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket de facturation"])},
  "dailyPointSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résumé quotidien des points"])},
  "chooseOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir l'option"])},
  "signedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signé le"])},
  "pointsAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alerte de points"])},
  "openedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert par"])},
  "createdOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Créé le ", _interpolate(_named("date"))])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "ticketOpenedBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("id")), " ouvert par"])},
  "ticketReplies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numb")), " réponse(s)"])},
  "assignedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné à"])},
  "hostOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'exploitation"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enregistrer"])},
  "saveAndSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder et enregistrer"])},
  "hostSoftwares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logiciels"])},
  "hostRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôles hôte"])},
  "hostTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types hôte"])},
  "hostShells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Shells"])},
  "hostFunctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Functions"])},
  "hostSuppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Suppliers"])},
  "datacenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datacenters"])},
  "hostServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Services"])},
  "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom du modèle"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse"])},
  "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute"])},
  "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critique"])},
  "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrats"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "incomingEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails Rentrants"])},
  "contractInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information du contrat"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte client"])},
  "selectAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un client"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sous-catégorie"])},
  "subCategoriesNumb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune sous-catégorie"]), _normalize(["Une sous-catégorie"]), _normalize([_interpolate(_named("count")), " sous-catégories"])])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir"])},
  "categoryShowable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible pour le client"])},
  "showable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
  "noShowable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non visible"])},
  "emptyStateTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pas de ", _interpolate(_named("title"))])},
  "emptyStateSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous ne disposez pas de ", _interpolate(_named("title")), ". Contactez le support si vous pensez qu'il s'agit d'une erreur."])},
  "resDateShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de rés."])},
  "autoRenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement automatique"])},
  "pointsTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de points"])},
  "pointsCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points restants"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte"])},
  "ticketUnassignedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket retirer avec succès."])},
  "ticketAssignedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket assigné avec succès."])},
  "deleteTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer le ticket #", _interpolate(_named("id"))])},
  "pointLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("points_current")), " pts restants"])},
  "deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, vous êtes sur le point de supprimer définitivement cet élément. Souhaitez-vous continuer ?"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour le"])},
  "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour le"])},
  "addTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une équipe"])},
  "customerReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport d'interventions"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "contractType1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de maintenance à points"])},
  "contractType2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de maintenance à points mensuel"])},
  "contractType3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de maintenance informatique forfaitaire"])},
  "contractType4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de maintenance téléphonique forfaitaire"])},
  "contractType5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de maintenance informatique et téléphonique forfaitaire"])},
  "contractType6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non facturable"])},
  "captureFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturer/Libérer"])},
  "resDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de résolution"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer"])},
  "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir un ticket"])},
  "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer le ticket"])},
  "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouvrir le ticket"])},
  "closeTicketNumb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clôturer le ticket #", _interpolate(_named("id"))])},
  "reopenTicketNumb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rouvrir le ticket #", _interpolate(_named("id"))])},
  "add an alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une alerte"])},
  "remove alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'alerte"])},
  "auto closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clôture automatique"])},
  "auto closure enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clôture automatique activée"])},
  "auto closure delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["délai de clôture automatique"])},
  "auto closure message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message de clôture automatique"])},
  "auto closure alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertes de clôture automatique"])},
  "default status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut par défaut"])},
  "applied on customer reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appliqué lors d'une réponse client"])},
  "applied on admin reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appliqué lors d'une réponse admin"])},
  "prevents auto closure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empêche la clôture automatique"])},
  "prevents closure survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empêche l'enquête de clôture"])},
  "delay in business days before closing the ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["délai en jours ouvrés avant la clôture du ticket"])},
  "the reply text sent when closing the ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le texte de la réponse envoyé dans le ticket lors de la clôture"])},
  "check the box to enable the feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cochez la case pour activer la fonctionnalité"])},
  "if required, you can configure intermediate alerts before closing the ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si besoin, vous pouvez configurer des alertes intermédiaires avant la fermeture du ticket"])},
  "closeTicketConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer que le problème a bien été résolu avant de fermer ce ticket de support."])},
  "reopenTicketConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de rouvrir ce ticket de support. Voulez-vous continuez ?"])},
  "controls the automatic closure of the ticket after a given delay in days without an answer from the customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle la fermeture automatique du ticket après un délai donné en jours sans réponse du client"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouvrir"])},
  "openPbxHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
  "onCallDuty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat d'astreinte"])},
  "internalReplyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce contenu est une note en interne"])},
  "opens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverts"])},
  "spentOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps passé le"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
  "editMembersListDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ceci est le mode d'édition de l'équipe de support ", _interpolate(_named("name")), ". Ici, vous pouvez ajouter ou supprimer plusieurs utilisateurs en même temps."])},
  "addToTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à l'équipe"])},
  "addTeamMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des membres à l'équipe"])},
  "addTeamMembersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore ajouté de membres à cette équipe de support. Vous pouvez commencer à rechercher et à ajouter un ou plusieurs utilisateurs."])},
  "quickAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout rapide"])},
  "activityFeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux d'activité"])},
  "editSupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les infos de l'équipe"])},
  "editMembersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode édition"])},
  "quitEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter mode édition"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
  "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de catégorie"])},
  "subCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de sous-catégorie"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une catégorie"])},
  "selectContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un contrat"])},
  "selectSupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une équipe de support"])},
  "timeSpent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps consacré: ", _interpolate(_named("numb")), " min"])},
  "timeSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche horaire"])},
  "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes tickets"])},
  "ticketsforTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journaliers"])},
  "ticketsForTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaires"])},
  "ticketsUnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assignés"])},
  "noDataFoundTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun tickets"])},
  "noDataFoundTicketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de résultats pour ce filtre"])},
  "noResultFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucuns résultats trouvés"])},
  "resultNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat introuvable"])},
  "resultNotFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun contact n'a été trouvé avec ce numéro de téléphone"])},
  "goBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page d'acceuil"])},
  "assignedTicketContacts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contact associé au ticket"]), _normalize(["Contact associé au ticket"]), _normalize(["Contacts associés au ticket "])])},
  "selectPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une priorité"])},
  "selectSurvey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une enquête"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
  "allTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les tickets"])},
  "selectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un statut"])},
  "nextRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain renouvellement"])},
  "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assigner"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviter"])},
  "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° facture"])},
  "closedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
  "ticketClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket clôturé"])},
  "ticketsClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets clôturé"])},
  "ticketOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ouvert"])},
  "buyingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat"])},
  "sellingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revente"])},
  "grab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturer"])},
  "grabAndGoToTicketDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturer et voir les détails du ticket"])},
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "serviceStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
  "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendu (sans facture)"])},
  "lastInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière facture"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à propos"])},
  "timeInMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps (en minutes)"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse"])},
  "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
  "created at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
  "folders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "folderSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du dossier"])},
  "billingFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence de facturation"])},
  "readyToBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt à facturer"])},
  "terminated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rompu"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer le document"])},
  "uploadFileDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier ou glisser-déposer"])},
  "currentlyBeingDeployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de déploiement"])},
  "somethingWrongHappened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, un problème est survenu."])},
  "survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête"])},
  "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])},
  "quoteInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis en cours"])},
  "addressEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "licenceMargins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marges des licences"])},
  "newBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle facture"])},
  "licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
  "licenceBillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation de licence"])},
  "recurringBillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation récurrente"])},
  "initialBillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation ponctuelle"])},
  "oneOffMargins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marges non récurrentes"])},
  "oneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponctuelle"])},
  "noKeyProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aucune clé fournie"])},
  "businessInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations commerciales"])},
  "shippingDeliveryInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition, livraison et installation"])},
  "technicalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations techniques"])},
  "billingCopies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation des copies"])},
  "personnalProfessionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service rendu à une personne, formation professionnelle"])},
  "customerFilesDetailHeaderTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dossier ", _interpolate(_named("name"))])},
  "customerFilesDetailHeaderSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fileType")), " ", "#", _interpolate(_named("id"))])},
  "lastBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières factures"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaines factures"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agrandir"])},
  "recurringMargins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marges récurrentes"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "viewLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
  "userListToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs pouvant être ajoutés."])},
  "accountEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email du compte"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "deleteEntity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer ", _interpolate(_named("entity"))])},
  "ticketDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket supprimé avec succès"])},
  "ticketClosedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket fermé avec succès."])},
  "ticketOpenedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ouvert avec succès."])},
  "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets ouverts"])},
  "invoicingTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket de facturation"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
  "takeContactOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détacher le contact du ticket."])},
  "closedTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets fermés"])},
  "goToWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au site web"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
  "successfullyCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié avec succès"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["téléphone"])},
  "consumptionsOnInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer le détail des consommations"])},
  "agentsIntoFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents liés au dossier"])},
  "contactsIntoFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts liés au dossier"])},
  "salesRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
  "salesManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager commercial"])},
  "noLicence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune licence"])},
  "noLicenceDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune licence sur ce dossier"])},
  "techRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technicien"])},
  "techManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager technique"])},
  "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
  "bannerSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
  "bannerProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
  "customerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le client"])},
  "customerInfoDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails principaux et infos 3CX"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "newMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mémo"])},
  "memos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mémos"])},
  "newDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau document"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "documentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du document"])},
  "bulkYear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " an"]), _normalize([_interpolate(_named("count")), " ans"])])},
  "bulkMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " mois"]), _normalize([_interpolate(_named("count")), " mois"])])},
  "bulkWeek": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " semaine"]), _normalize([_interpolate(_named("count")), " semaines"])])},
  "bulkDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " jour"]), _normalize([_interpolate(_named("count")), " jours"])])},
  "bulkHour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " heure"]), _normalize([_interpolate(_named("count")), " heures"])])},
  "bulkMinute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " minute"]), _normalize([_interpolate(_named("count")), " minutes"])])},
  "bulkSecond": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " seconde"]), _normalize([_interpolate(_named("count")), " secondes"])])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "memoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrire un mémo..."])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heure"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])},
  "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "lessThanMinute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins d'une minute"])},
  "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a"])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par"])},
  "host": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hébergeur"]), _normalize(["hébergeurs"])])},
  "noDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée"])},
  "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de données pour le moment"])},
  "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
  "noResultFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun resultat trouvé"])},
  "noDataFoundOpenTicketsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de tickets ouverts."])},
  "noDataFoundClosedTicketsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de tickets fermés."])},
  "noResultFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez d'affiner votre recherche ou filtrer autrement."])},
  "noDataFoundInvoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture"])},
  "noDataFoundInvoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez pas de facture."])},
  "noDataFoundDocumentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Documents"])},
  "noDataFoundDocumentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne disposez d'aucun document. Contactez votre supérieur hiérarchique si cela n'est pas censé se produire."])},
  "noDataFoundContactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Contacts"])},
  "noDataFoundContactDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau contact ou importez une liste de contact."])},
  "noDataFoundContractTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Contrat"])},
  "noDataFoundContractDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau contrat."])},
  "noDataFoundCtiTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun CTI"])},
  "noDataFoundCtiDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer une nouvelle configuration CTI."])},
  "noDataFoundRoutingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Routages Géographiques"])},
  "noDataFoundRoutingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau routages géographiques."])},
  "noDataFoundCallQueueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune file d'appels"])},
  "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer une nouvelle file d'appels."])},
  "noDataFoundCallQueueGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de file d'appels"])},
  "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau groupe de file d'appels."])},
  "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun créneau horaire enregistré. Veuillez en ajouter un nouveau en cliquant sur le bouton correspondant."])},
  "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun jour exceptionel enregistré. Veuillez en ajouter un nouveau en cliquant sur le bouton correspondant."])},
  "noDataFoundSMSListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune listes de SMS"])},
  "noDataFoundSMSListDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer une nouvelle liste de SMS."])},
  "noDataFoundDashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun tableau de bord"])},
  "noDataFoundElementGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau groupe."])},
  "noDataFoundDashboardDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par sélectionner des éléments et une période de temps."])},
  "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées bancaires"])},
  "mandateSignatureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de signature du mandat"])},
  "mandateReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence unique de mandat"])},
  "assignAutomatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer automatiquement (laisser vide)"])},
  "updateClientRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les dossiers du client"])},
  "mandateGoCardless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats GoCardless"])},
  "customValueGoCardless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur libre (personnalisée) du compte GoCardless"])},
  "mandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandats"])},
  "newEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel employé(e)"])},
  "interviewedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien le"])},
  "applyRecruitmentToCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un candidat pour le poste de "])},
  "applyRecruitmentToCandidateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir le candidat propice à ce dossier de recrutement. Vous avez la possibilité de le modifier ou le supprimer"])},
  "addToCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un candidat"])},
  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature"])},
  "newEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel employeur"])},
  "interviewsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature"])},
  "interviewsHeadline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liste des candidats du dossier de recrutement pour le poste de ", _interpolate(_named("recruitment"))])},
  "quickSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche rapide de candidats et d'employeurs"])},
  "externalEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeur externe"])},
  "externalEmployerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier le nom de cet employeur externe"])},
  "newFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau filtre"])},
  "candidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employés et candidats"])},
  "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "mainContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact principal"])},
  "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "arrivedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivée"])},
  "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
  "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de sécurité sociale"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "noTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun titre"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
  "chooseClientThenContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour créer un nouveau ticket, vous devez d'abord choisir le client associé au ticket et au moins un de ses contacts"])},
  "prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-requis"])},
  "provideClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un contact est requis pour associer le ticket"])},
  "teamTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets d'équipe"])},
  "language1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue 1"])},
  "language2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue 2"])},
  "language3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue 3"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "attended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participé(e)"])},
  "not_attended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'a pas participé(e)"])},
  "matching_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspond aux besoins"])},
  "not_matching_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne correspond aux besoins"])},
  "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intéressé(e)"])},
  "not_interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas intéressé(e)"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtre"])},
  "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "candidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidat"])},
  "selectAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection matinée"])},
  "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner des lignes"])},
  "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " lignes"])},
  "selectPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection après-midi"])},
  "inputCantBeNull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ ne peut être vide"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employés"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employé"])},
  "employers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeurs"])},
  "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeur"])},
  "specialities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialités"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "startOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez le"])},
  "endOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ternimez le"])},
  "noMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun message n'a encore été posté"])},
  "no posted reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aucune réponse postée"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "profilePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo de profil"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer"])},
  "streetAddress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse 1"])},
  "streetAddress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse 2"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "specialitiesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer jusqu'à 4 specialités à ce candidat"])},
  "civilStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État civil"])},
  "civilStatusDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail concernant l'état civil et administratif du candidat"])},
  "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
  "speciality1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité 1"])},
  "speciality2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité 2"])},
  "speciality3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité 3"])},
  "speciality4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité 4"])},
  "otherPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro alternatif"])},
  "otherPhoneDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préciser, le cas écheant"])},
  "editOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'organisation"])},
  "lastUpdateOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dernière modification a été faite le"])},
  "levelOfStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau d'étude"])},
  "diplomas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme"])},
  "diplomas2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme (2)"])},
  "diplomas3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme (3)"])},
  "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métier"])},
  "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
  "specialityNoProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialité non fournie"])},
  "salaryBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien salaire"])},
  "salaryAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau salaire"])},
  "hobby1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby 1"])},
  "hobby2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby 2"])},
  "hobby3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby 3"])},
  "commutingInformations1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information transport (1)"])},
  "commutingInformations2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information transport (2)"])},
  "commutingInformations3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information transport (3)"])},
  "hasAllergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergique"])},
  "hasAllergyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez spécifié dans le cas écheant la liste des allergies du candidat"])},
  "allergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergie(s)"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
  "interviewDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'entretien"])},
  "availableAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à partir du"])},
  "availableDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier si le candidat est disponible pour de futur contrat"])},
  "availableForCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible dans le pays"])},
  "availableForAbroad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à l'étranger"])},
  "availableForHoused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for housed"])},
  "availableForNotHoused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available for not housed"])},
  "availableForFullTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à plein-temps"])},
  "availableForPartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à mi-temps"])},
  "availableForPartTimeAm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à mi-temps (matin)"])},
  "availableForPartTimePm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible à mi-temps (après-midi)"])},
  "availableWeekEndAm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible le week-end (matin)"])},
  "availableWeekEndPm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible le week-end (après-midi)"])},
  "availableForWeekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible en week-end"])},
  "availableForExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
  "applicantInformation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Information sur l'", _interpolate(_named("userType"))])},
  "personalDetailsAndApplication": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fiche personnelle établies sur l'", _interpolate(_named("userType"))])},
  "disponibilityHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilités du candidat"])},
  "disponibilityHeadlineDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournir les informations de disponibilité du candidat, comme la date de disponibilité ou les disponibilités en semaine"])},
  "MonAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi (matin)"])},
  "MonPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi (après-midi)"])},
  "TueAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi (matin)"])},
  "TuePM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi (après-midi)"])},
  "WedAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi (matin)"])},
  "WedPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi (après-midi)"])},
  "ThuAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi (matin)"])},
  "ThuPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi (après-midi)"])},
  "FriAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi (matin)"])},
  "FriPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi (après-midi)"])},
  "SatAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi (matin)"])},
  "SatPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi (après-midi)"])},
  "SunAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche (matin)"])},
  "SunPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche (après-midi)"])},
  "newSpeciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new speciality"])},
  "newSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de site"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oui"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
  "generateNewCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un nouveau CV"])},
  "generateNewCvDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de CV mais vous pouvez en créer un nouveau en cliquant sur cette zone"])},
  "ifApplicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le cas échéant"])},
  "globalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information générale"])},
  "recipientsListsInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les destinataires"])},
  "recipientsListsInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez entre une liste de destinataire et/ou plusieurs destinataires"])},
  "accountInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le compte"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
  "campaignCreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne crée avec succès"])},
  "modificationSavedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification sauvegardée avec succès"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté(e)"])},
  "addUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter utilisateurs"])},
  "addUsersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous ajouter de nouveaux utilisateurs ?"])},
  "removeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer utilisateurs"])},
  "removeUsersToAccountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un ou plusieurs utilisateurs ci-dessous si vous souhaitez les supprimer du compte"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marketing"])},
  "addNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel utilisateur"])},
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activer"])},
  "smsLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("charNumber")), " caractères pour ", _interpolate(_named("smsNumber")), " SMS"])},
  "typeYourMessageHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre message ici..."])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "landlinePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone fixe"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
  "readFullProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout le profile"])},
  "mobilePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])},
  "uploadAFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier"])},
  "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glisser-déposer"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepté"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "maritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation familiale"])},
  "fullResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV complet (avec coordonnées)"])},
  "truncatedResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV version compact (sans coordonnées)"])},
  "sendResumeByEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniquenement le CV sans coordonnées sera envoyé."])},
  "resumeSuccessfullySend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV envoyé avec succès"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "downloadsAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout télécharger"])},
  "errorRecipientsLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir une liste de destinataires ou un destinataire"])},
  "errorAccountId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir une compte"])},
  "campaignReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign prête"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
  "addYourComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commentaire..."])},
  "commentOnProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire général sur l'ensemble des expériences"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse e-mail ou mot de passe invalide"])},
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poster"])},
  "noDataFoundUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateurs"])},
  "noDataFoundUsersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun utilisateurs enregistrés sur ce compte"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "campaignInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur la campagne"])},
  "campaignDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail de la campagne et statistiques"])},
  "expeditorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'expéditeur"])},
  "expeditorNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom affiché pour le destinataire lors de la réception du SMS"])},
  "newOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle organisation"])},
  "newOrganisationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer une nouvelle organisation en remplissant le formulaire ci-dessous"])},
  "smsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de SMS"])},
  "accountCreatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte a été créé le"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "disactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactiver"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "notMarketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non marketing"])},
  "inputErrorInvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir un e-mail valide"])},
  "permissionToEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "permissionToEditDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à l'utilisateur la permission de modifier"])},
  "permissionToManageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les utilisateurs"])},
  "permissionToManageUsersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à l'utilisateur la permission de gérer les utilisateurs"])},
  "permissionToManageLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer la liste des destinataires"])},
  "permissionToManageListsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à l'utilisateur la permission de gérer la liste des destinataires"])},
  "ownedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détenu par"])},
  "collectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail de la collecte"])},
  "collectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de collecte"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau"])},
  "elementIsMandatory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("element")), " est obligatoire"])},
  "trunkName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du trunk"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumise"])},
  "readyToBeDeployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prête à être déployée"])},
  "collectOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverte"])},
  "statusName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du statut"])},
  "statusColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une couleur pour ce statut"])},
  "ticketStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut des tickets"])},
  "colletStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la collecte"])},
  "numberOfChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'enfant(s)"])},
  "coupleWithEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En couple avec un autre employé"])},
  "ownerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email du propriétaire"])},
  "collectionsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des collectes"])},
  "otherInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres informations"])},
  "otherInformationsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations secondaires sur le candidat"])},
  "otherInformationsFileDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Informations secondaires sur le ", _interpolate(_named("infoType"))])},
  "organisationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de l'organisation"])},
  "organisationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation details"])},
  "organisationInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation à laquelle vous êtes lié et les détails à ce sujet"])},
  "smsTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le type de SMS"])},
  "smsMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le contenu du SMS"])},
  "marketingDisclaimerPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention ! Pour tous les SMS marketing, le message doit finir par"])},
  "marketingDisclaimerPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est obligatoire afin de fournir à vos destinataires un moyen de se désabonner et de respecter les obligations de la CNIL."])},
  "campaignNameDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le nom de votre campagne"])},
  "recipientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le destinataire"])},
  "recipientInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez les informations du nouveau destinataire ici"])},
  "recipientListInformationNewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez les informations de la nouvelle liste ici"])},
  "recipientInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez ici les destinataires de votre SMS. Vous pouvez également combiner cette fonction avec une liste de destinataires"])},
  "signinToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "newPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre."])},
  "updateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'utilisateur"])},
  "updateOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'organisation"])},
  "updateUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les nouvelles informations ci-dessous"])},
  "updateCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la campagne de SMS"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajouter"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
  "billingMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen de facturation"])},
  "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campagne"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenu"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prêt"])},
  "newCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle campagne"])},
  "newRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau destinataire"])},
  "newAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau compte"])},
  "newAccountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer un nouveau compte en remplissant le formulaire ci-dessous"])},
  "newList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle liste"])},
  "beganDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer le"])},
  "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
  "lastModification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification le"])},
  "updated at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifié le"])},
  "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coordonnées"])},
  "familyMemberUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre de la famille modifié avec succès"])},
  "applicationUpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature modifié avec succès"])},
  "notePostedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémo posté avec succès"])},
  "candidateAppliedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau candidat a été lié a ce dossier de recrutement"])},
  "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campagnes"])},
  "recipientsLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes de destinataires"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinataire"])},
  "licenceKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clef de licence"])},
  "licenceEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email lié à la licence"])},
  "licencePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone lié à la licence"])},
  "licenceCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société lié à la licence"])},
  "licenceContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact lié à la licence"])},
  "licenceResellerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du revendeur de la licence"])},
  "licenceAdminEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email administrateur"])},
  "operatorFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom opérateur"])},
  "operatorLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom opérateur"])},
  "operatorExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extention opérateur"])},
  "operatorVoicemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondeur opérateur"])},
  "operatorEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email opérateur"])},
  "pbxConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration du PBX"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restorer"])},
  "pbxServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur PBX"])},
  "adminUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surnom administrateur"])},
  "adminPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe administrateur"])},
  "ipAddressLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse IP local"])},
  "ipDynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse IP dynamique"])},
  "portHttp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port HTTP"])},
  "portHttps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port HTTPS"])},
  "portSip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port SIP"])},
  "portTunnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port Tunnel"])},
  "dnsSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suffixe DNS"])},
  "dnsLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS Local"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste"])},
  "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technique"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valider"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
  "adminCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiants de l'administrateur"])},
  "adminCredentialsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le mot de passe n'est pas renseigné, il sera généré aléatoirement à la validation"])},
  "ipAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP"])},
  "ipAddressDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aucune adresse IP n'est fournit, elle sera générer automatiquement à la validation"])},
  "collectAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès collecte"])},
  "openClientCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir la collecte"])},
  "ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ports"])},
  "trunkSimultaneousCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels simultanés"])},
  "trunkHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôte"])},
  "trunkAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
  "trunkPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "portsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PortsDetail"])},
  "dns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS"])},
  "dnsDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNSDetail"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sexe"])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relation"])},
  "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrat"])},
  "contractsHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de tout les contracts (actuelles et précédents)"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail"])},
  "addNewContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau contrat"])},
  "addFamilyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter membre de la famille"])},
  "familyInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations concernant la famille"])},
  "familyCompositionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de la composition de famille"])},
  "familyComposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composition de famille"])},
  "familyCompositionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détailler brièvement la composition de la famille"])},
  "familyInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations familiale"])},
  "familyInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifier les informations de famille, les détails de l'habitation et son accès"])},
  "habitationInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de logement"])},
  "habitationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail sur le logement et les accès"])},
  "habitationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logement"])},
  "habitationFloorArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface habitable"])},
  "habitationNumberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pièces"])},
  "habitationOtherInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres informations sur le logement"])},
  "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
  "accessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accessDescription"])},
  "habitationFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étage"])},
  "habitationDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de la porte d'entrée"])},
  "habitationInterphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interphone"])},
  "habitationKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clef"])},
  "noContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun contrats"])},
  "noContractDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau contrat"])},
  "newContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau contrat"])},
  "agencyContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat d'agence"])},
  "noNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mémos n'a encore été posté"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémos"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
  "notSpecify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas spécifier"])},
  "writeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez une description..."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "usersAddedSuccessfully": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utilisateur ajouté avec succès"]), _normalize(["Utilisateurs ajoutés avec succès"])])},
  "quickAddTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter rapidement un utilisateur"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe"])},
  "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipes"])},
  "teamsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une liste de toutes les équipes support, avec leur nom, leur description et le nombre d'utilisateurs liés."])},
  "recruitments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recrutements"])},
  "recruitmentsHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des postes de recrutements"])},
  "addNewPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau dossier"])},
  "noPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier de recrutement"])},
  "noPositionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par créer un nouveau dossier de recrutement"])},
  "newPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau dossier"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "usersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
  "numbUsers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucun utilisateur"]), _normalize([_interpolate(_named("numb")), " utilisateur"]), _normalize([_interpolate(_named("numb")), " utilisateurs"])])},
  "deleteUsers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Supprimer utilisateur"]), _normalize(["Supprimer utilisateurs"])])},
  "ticketsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun ticket"]), _normalize([_interpolate(_named("numb")), " ticket"]), _normalize([_interpolate(_named("numb")), " tickets"])])},
  "usersList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liste des utilisateurs du compte ", _interpolate(_named("accountName")), ". Vous pouvez mettre à jour les utilisateurs du compte ici."])},
  "updateAccountUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les utilisateurs du compte"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez"])},
  "helpToSearchDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez cet outil pour rechercher rapidement des candidats et des employeurs sur l'ensemble de notre plateforme. Vous pouvez également utiliser les modificateurs de recherche figurant dans le pied de page ci-dessous pour limiter les résultats aux seuls candidats ou employeurs."])},
  "helpToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide à la recherche"])},
  "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau ticket"])},
  "forHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour l'aide"])},
  "forCandidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour les candidats"])},
  "forEmployers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour les employeurs"])},
  "forEmployersLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour chercher parmis les employeurs"])},
  "accountSectionHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Compte ", _interpolate(_named("accountName"))])},
  "noProvided": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " non fourni(e)"])},
  "accountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le compte pour cette campagne"])},
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comptes"])},
  "goodMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonjour"])},
  "goodAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonne journée"])},
  "goodEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonne soirée"])},
  "passwordResetMustContain12Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins 12 caractères"])},
  "passwordResetMustContainOneUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins une majuscule"])},
  "passwordResetMustContainOneSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins un spécial caractère"])},
  "passwordResetMustContainOneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit contenir au moins un nombre"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom"])},
  "contractTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du contrat"])},
  "listsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de liste"])},
  "recruitmentFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dossier de recrutement"])},
  "salaryExpectations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire offert"])},
  "ageMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge minimum souhaité"])},
  "ageMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge minimum souhaité "])},
  "languageExpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue souhaitée"])},
  "maritalStatusExpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation familiale souhaitée"])},
  "isSignerContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire du contrat"])},
  "collectContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact collecte"])},
  "isSignerIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire mandat SEPA"])},
  "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout supprimer"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier clients"])},
  "invoicing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des services"])},
  "customer-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier clients"])},
  "ticketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
  "smart-routings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routages intelligents"])},
  "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services SMS"])},
  "webradios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radios Web"])},
  "pbx-hosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtes PBX"])},
  "expert-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experts statistiques"])},
  "expert-stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experts statistiques"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
  "information-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectes"])},
  "client.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
  "client.accountant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptabilité"])},
  "client.technician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technicien"])},
  "allPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès total"])},
  "createPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "editPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "deletePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "viewPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire"])},
  "updatePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
  "writePermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d'écriture"])},
  "permission.*": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès total"])},
  "permission.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "permission.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "permission.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "permission.view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire"])},
  "permission.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
  "permission.write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d'écriture"])},
  "permission.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "permission.enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer/Désactiver"])},
  "permission.view-own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire les siens"])},
  "permission.edit-own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les siens"])},
  "hasUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utilisateur est lié à ce contact"])},
  "hasNoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur lié pour le moment, veuillez revenir plus tard"])},
  "legalRepresentative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant légal"])},
  "invoicesRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact facturation"])},
  "technicalContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact technique"])},
  "legalRepresentativeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rep. légal"])},
  "invoicesRecipientShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
  "technicalContactShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technique"])},
  "collectContactShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte"])},
  "myClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès espace client"])},
  "is_signer_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire du contrat"])},
  "is_signer_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signataire du mandat SEPA"])},
  "is_collect_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact collecte"])},
  "ticketingDoNotNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support: ne jamais adresser de notifications à ce contact"])},
  "ticketingCopyToSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support: adresser les notifications en copie au commercial"])},
  "newslettersRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire des newsletters"])},
  "cfdToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un nouveau token de call flow"])},
  "disponibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
  "mainInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
  "mainInformationDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Informations principales concernant le ", _interpolate(_named("infoType"))])},
  "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
  "personalInformationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournissez les informations ci-dessous pour établir un profil utilisateur"])},
  "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres avancés"])},
  "deleteCandidate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer le candidat ", _interpolate(_list(0))])},
  "deleteTeam": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer l'équipe ", _interpolate(_list(0))])},
  "deleteRecurringFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer facturation #", _interpolate(_named("id"))])},
  "deleteLicenceFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la licence #", _interpolate(_named("id"))])},
  "seeMore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voir le profil de ", _interpolate(_named("user"))])},
  "seeCustomer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voir le client ", _interpolate(_named("customer"))])},
  "seeTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voir le ticket #", _interpolate(_named("id"))])},
  "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
  "deleteEmployer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer l'employeur ", _interpolate(_list(0))])},
  "deleteContract": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer le contrat ", _interpolate(_list(0))])},
  "deleteRecruitment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer le dossier de recrutement ", _interpolate(_list(0))])},
  "deleteNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le mémos"])},
  "deleteSpeciality": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Supprimer la specialité ", _interpolate(_list(0)), " (", _interpolate(_list(1)), ")"])},
  "deleteApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la candidature"])},
  "deleteTicketStatus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer le status de ticket ", _interpolate(_named("name"))])},
  "deleteTicketCategory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la catégorie de ticket ", _interpolate(_named("name"))])},
  "deleteTicketSubCategory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer la sous-catégorie ", _interpolate(_named("name"))])},
  "wishToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous continuer ?"])},
  "itemDeletedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " supprimé(e) avec succès"])},
  "itemAddedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " créé(e) avec succès"])},
  "itemUpdatedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " modifié(e) avec succès"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "callQueueNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de file d'appel"])},
  "numberOfCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels"])},
  "numberOfAnsweredCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels répondus"])},
  "numberOfCallsAnsweredByAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels répondus par un autre"])},
  "numberOfCallsTransferredByAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels transférés par un agent"])},
  "numberOfAbandonedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels abandonnés"])},
  "numberOfCallsDeterred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels dissuadés"])},
  "averageWaitingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps d'attente moyen"])},
  "averageWaitingTimeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps d'attente moyen dans une file"])},
  "averageDropoutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temps d'abandon moyen"])},
  "averageCallDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée moyenne d'appel"])},
  "queueDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détail de la file"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détails"])},
  "support team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe de support"])},
  "supportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe de support"])},
  "removeTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer l'équipe"])},
  "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro"])},
  "didCallers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DID Callers"])},
  "numberOfRepeatedCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre d'appels répétés"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé API"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte actif"])},
  "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["libellé"])},
  "renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["renouvellement"])},
  "companyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de société"])},
  "newProspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau prospect"])},
  "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité"])},
  "contactInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
  "commercialInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations commerciales"])},
  "representative_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du représentant"])},
  "origin_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine prospect/client"])},
  "sector_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité"])},
  "accountant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatable"])},
  "accountsReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte tiers (si différent)"])},
  "activityCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Activité"])},
  "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque / Enseigne"])},
  "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital"])},
  "cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster (groupement technique)"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeant"])},
  "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "ics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant créancier SEPA"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
  "organisationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de société"])},
  "registrationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'enregistrement (SIRET)"])},
  "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité"])},
  "sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteurs d'activité"])},
  "taxRegistrationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TVA intracommunautaire"])},
  "zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone géographique"])},
  "personalProspecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospection personnelle"])},
  "leadGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead via Google"])},
  "leadAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead - autre"])},
  "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandation"])},
  "businessProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apporteur d'affaire à rémunerer"])},
  "another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "administrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrations"])},
  "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâtiments"])},
  "distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution"])},
  "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industries"])},
  "leisureTourism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisirs et tourisme"])},
  "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médias"])},
  "newTechnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles technologies"])},
  "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé"])},
  "servicesType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "transports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droits"])},
  "mergeInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regrouper les factures"])},
  "chargeVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assujetti à la TVA"])},
  "time segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segment de temps"])},
  "points renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["renouvellement de points"])},
  "points quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantité de points"])},
  "support contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrat de support"])},
  "support contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrats de support"])},
  "one-time addition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajout ponctuel"])},
  "monthly addition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["renouvellement mensuel auto"])},
  "ticketing configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuration du support"])},
  "general configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuration générale"])},
  "points configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuration des points"])},
  "Action successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
  "controls how tickets and reports are managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrôle la gestion des tickets et des rapports"])},
  "controls how points are used and billed to customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrôle l'utilisation et la facturation des points aux clients"])},
  "time value of a point (minutes)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valeur en temps d'un point (minutes)"])},
  "price value of a point (euros)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valeur en prix d'un point (euros)"])},
  "customer closure notification delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["délai de notification de fermeture client"])},
  "this setting allow you to specify a delay in minutes to wait before sending the ticket closure notification to the customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce paramètre vous permet de spécifier un délai en minutes à attendre avant d'envoyer la notification de fermeture du ticket au client"])},
  "team reports recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinataires des rapports d'équipe"])},
  "monthly and weekly report about number of assigned ticket, replies, replies per ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rapport mensuel et hebdomadaire sur le nombre de tickets attribués, réponses, réponses par ticket"])},
  "separate emails with commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["séparez les e-mails par des virgules"])},
  "used to calculate the number of points a ticket represents, based on time tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisé pour calculer le nombre de points qu'un ticket représente, basé sur le log de temps"])},
  "this amount of point would be retired from the support contract if applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce montant de point sera alors retiré du contrat de support, si applicable"])},
  "used to invoice support charge to your customers, based on the point system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisé pour facturer l'utilisation du support à vos client, basé sur le système de points"])},
  "Prevents auto status to be applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empêche le statut automatique d'être appliqué"])},
  "sidebar": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "tickets": {
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverts"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermés"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de tickets"])},
      "incomingEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails Rentrants"])}
    },
    "incomingEmails": {
      "unlinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non liés à un client"])},
      "replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses à des tickets clôturés"])}
    },
    "tools": {
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
      "sftpAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Accounts"])}
    },
    "survey": {
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])},
      "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les enquêtes"])}
    },
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "subMenu": {
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances"])},
      "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'appels"])}
    },
    "specialNavigation": {
      "expertService3CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Experts 3CX"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "georouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routages Géographiques"])},
      "callqueuegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de files d'appels"])},
      "callqueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files d'appels"])}
    },
    "support": {
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "ticketCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories de tickets"])},
      "ticketStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuts de tickets"])},
      "emailFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres d'email"])},
      "supportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipes support"])}
    },
    "hosts": {
      "hosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergeurs"])},
      "hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
      "software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logiciel"])},
      "infrastructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastructure"])},
      "operatingSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes d'exploitation"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôles"])},
      "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types"])},
      "shells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shells"])},
      "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctions"])},
      "supliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de données"])},
      "hostServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services hébergeurs"])}
    }
  },
  "header": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchTicketPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet, date de création ou mise à jour"])},
    "searchTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un ticket"])},
    "searchInvoicePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures, dates ou totaux"])},
    "searchSftpAccountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'hôte ou nom d'utilisateur"])},
    "searchAddMembersToSupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou adresse e-mail"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Actif"])},
    "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Compte"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Ticket"])},
    "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre profil"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par statut"])}
  },
  "chartTable": {
    "callType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
    "avgPerCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyenne"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne fixe"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spéciale"])},
    "callCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
    "callDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels"])},
    "fileNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "choosePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la période"])},
    "chooseResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la ressource"])},
    "resourceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de ressource"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiques"])},
    "data": {
      "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrant"])},
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortant"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondu"])},
      "unanswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Répondu"])}
    }
  },
  "dashboards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalier"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaire"])},
    "monthlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord Mensuel de"])},
    "monthlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données mensuelles de"])},
    "dailyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord Journalier de"])},
    "dailyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données journalières de"])},
    "hourlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord par heure de "])},
    "hourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données horaires de"])},
    "donutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels quotidien répondu et non répondu"])},
    "stackedColumnCategories": {
      "incomingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels Entrant"])},
      "outgoingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels Sortant"])},
      "answeredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels Répondu"])},
      "unansweredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels non Répondu"])},
      "incomingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Rentrant"])},
      "outgoingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Sortant"])},
      "answeredDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Répondu"])}
    },
    "error": {
      "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
      "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données pour cette période n'ont pas été trouvées ou n'existent pas encore, nous sommes désolés pour ce désagrément."])}
    }
  },
  "warning": {
    "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
    "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune file d'attente d'appel n'a encore été créée. Veuillez en ajouter une en cliquant sur le bouton 'Nouvelle file d'appel'."])},
    "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de file d'attente d'appel n'a encore été créée. Veuillez en ajouter un en cliquant sur le bouton 'Nouveau groupe de file d'appel'."])},
    "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun créneau horaire n'a encore été créé. Veuillez en ajouter un en cliquant sur le bouton 'Ajouter un nouveau créneau horaire'."])},
    "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun jours exceptionnels n'a encore été créé. Veuillez en ajouter un en cliquant sur l'un des boutons 'Ajouter une exception'."])}
  },
  "pbxExtension": {
    "selectData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une données"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une période"])}
  },
  "home": {
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])}
  },
  "ticket": {
    "ticketForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de création de Ticket"])},
    "typeDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez vos questions/problèmes ci-dessous"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priorité"])},
    "supportContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrats de support"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réponse type"])},
    "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution estimée"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
    "selectProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le problème"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "emailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet du ticket"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "typeAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez le problème ci-dessus"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
    "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template created successfully"])},
    "writeReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre réponse pour le ticket"])},
    "header": {
      "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverts"])},
      "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes tickets"])},
      "ticketsforTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journaliers"])},
      "ticketsForTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaires"])},
      "ticketsUnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assignés"])},
      "teamTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets d'équipe"])}
    },
    "sendToContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la réponse au client"])},
    "sendToContactsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez d'envoyer la réponse au client et de le notifier par e-mail"])},
    "sendToAssignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier le technicien assigné"])},
    "sendAsInternalReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réponse sera envoyée en note interne"])},
    "sendToAssigneeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez de notifier le technicien assigné par e-mail"])},
    "copyToSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier le commercial"])},
    "copyToSalesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez de notifier le commercial par e-mail"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer le ticket"])},
    "closeTicketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer et clôturer le ticket"])},
    "templateAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un modèle de réponse"])},
    "assignedContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts associés"])},
    "addAssignedContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associer un contact"])}
  },
  "invoiceTable": {
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturer ID"])},
    "invoiceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
    "totalWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant HT"])},
    "totalWithTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant TTC"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "searchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche ID"])},
    "searchNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche numéro"])},
    "searchMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "searchYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AAAA"])},
    "searchAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche montant"])}
  },
  "ticketTable": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe support"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouvrir"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner des lignes"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lignes"])},
    "confirmationCloseMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir fermer le ticket ?"])},
    "confirmationOpenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir rouvrir le ticket ?"])},
    "closeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket fermé avec succès."])},
    "openMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ouvert avec succès."])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
    "dialogTitleClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le ticket."])},
    "dialogTitleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le ticket."])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage de"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
    "searchStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche statut"])},
    "searchDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JJ/MM/AAAA"])}
  },
  "replySurveyTable": {
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])},
    "survey_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])},
    "sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent at"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "sftpAccountTable": {
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'hôte"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])}
  },
  "documentTable": {
    "allDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les documents"])},
    "yourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos documents"])}
  },
  "dialog": {
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner"])}
  },
  "profile": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de L'utilisateur"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails personnels"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
    "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé API"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Actif"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])}
  },
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libérer"])},
  "actionId": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a demarré l'appel"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a commencé l'appel et c'est terminé par la source"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a commencé et terminé l'appel"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a laissé l'utilisateur patienter, puis l'a redirigé vers"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirige l'utilisateur vers"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a refusé l'appel ou n'a pas répondu"])},
    "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a laissé le téléphone sonner et n'a pas répondu"])},
    "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a refusé l'appel. L'appel a été automatiquement redirigé vers"])},
    "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas pu répondre. L'appel a été automatiquement redirigé vers"])},
    "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas pu répondre car indisponible"])},
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le numéro sélectionné n'existe pas ou l'appel a échoué"])},
    "408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dont know about this action_id 408"])},
    "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a laissé le téléphone sonner et c'est mis en mode occupé"])},
    "418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a refusé l'appel ou l'a directement transféré à la messagerie vocal"])}
  },
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["début"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fin"])},
  "callAnalyser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseur d'appels"])},
  "callLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal d'appels"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une heure"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réinitialiser"])},
  "startAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commencé à"])},
  "redirectAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirigé à"])},
  "answeredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répondu à"])},
  "finishAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terminé à"])},
  "declineAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refusé à"])},
  "initializeAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initialisé à"])}
}