import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VCalendar from "v-calendar";
import ErrorHandling from "./mixins/ErrorHandling";
import Formatter from "./mixins/Formatter";
import { VueCookieNext } from "vue-cookie-next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import titleMixin from "./mixins/titleMixin";
import VueNextSelect from "vue-next-select";
import VueApexCharts from "vue3-apexcharts";

import "v-calendar/dist/style.css";
/* import specific icons */
import {
 faHome,
 faCalendarDay,
 faCalendarDays,
 faCalendarWeek,
 faChartLine,
 faHeadset,
 faClipboardList,
 faWrench,
 faGear,
 faComment,
 faEnvelope,
 faEnvelopeOpenText,
 faFilterCircleXmark,
 faRightFromBracket,
 faRightToBracket,
 faUserTie,
 faServer,
 faSignInAlt,
 faUser,
 faUserCheck,
 faUserXmark,
 faUsers,
 faTags,
 faUserClock,
 faStopwatch,
 faChevronCircleRight,
 faCalendarTimes,
 faCalendarCheck,
 faHomeUser,
 faCoins,
 faRepeat,
 fa1,
 faIdCard,
 faSpinner,
 faCircleCheck,
 faCircleNotch,
 faFileInvoiceDollar,
 faCircleXmark,
 faBan,
 faStar,
 faBusinessTime,
 faClock,
 faLock,
 faLockOpen,
 faGraduationCap,
 faExternalLinkAlt,
 faTrashCan,
 faToolbox,
 faCheck,
 faCalendar,
 faCommentDots,
 faComments,
 faCheckDouble,
 faSlash,
 faReceipt,
 faCartShopping,
 faPersonRunning,
 faHand,
 faTableList,
 faTriangleExclamation,
 faReply,
 faSpellCheck,
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(
 faHome,
 faCalendarDay,
 faCalendarDays,
 faCalendarWeek,
 fa1,
 faCalendarTimes,
 faCalendarCheck,
 faChartLine,
 faChevronCircleRight,
 faCircleCheck,
 faCircleNotch,
 faCircleXmark,
 faClipboardList,
 faCoins,
 faComment,
 faEnvelope,
 faEnvelopeOpenText,
 faFileInvoiceDollar,
 faFilterCircleXmark,
 faGear,
 faHomeUser,
 faHeadset,
 faIdCard,
 faRepeat,
 faRightFromBracket,
 faRightToBracket,
 faStopwatch,
 faServer,
 faSpinner,
 faSignInAlt,
 faTags,
 faUserTie,
 faUser,
 faUserClock,
 faUsers,
 faUserCheck,
 faUserXmark,
 faWrench,
 faBan,
 faStar,
 faBusinessTime,
 faClock,
 faLock,
 faLockOpen,
 faGraduationCap,
 faExternalLinkAlt,
 faTrashCan,
 faToolbox,
 faCheck,
 faCalendar,
 faCommentDots,
 faComments,
 faCheckDouble,
 faSlash,
 faReceipt,
 faCartShopping,
 faPersonRunning,
 faHand,
 faTableList,
 faTriangleExclamation,
 faReply,
 faSpellCheck
);

import i18n from "./i18n";
import "./assets/css/tailwind.css";
import "./assets/css/main.css";
// axios.defaults.baseURL = `${this.$cookie.getCookie("API")}/api`;
axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.token;

createApp(App)
 .use(i18n)
 .use(CKEditor)
 .use(VCalendar)
 .use(store)
 .use(router)
 .use(VueCookieNext)
 .use(VueApexCharts)
 .component("font-awesome-icon", FontAwesomeIcon)
 .component("vue-select", VueNextSelect)
 .mixin(ErrorHandling)
 .mixin(titleMixin)
 .mixin(Formatter)
 .mount("#app");
