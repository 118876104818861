<template>
 <!--  <div class="flex mt-1">
  <div class="relative flex-grow focus-within:z-10">
   <div
    :class="[
     !onFocus
      ? `absolute search-filter__icon border-t border-l border-b border-gray-300 inset-y-0 p-2 left-0 flex items-center pointer-events-none`
      : `absolute search-filter__icon inset-y-0 p-2 left-0 flex items-center pointer-events-none`,
     'rounded-l',
    ]"
    :style="[
     onFocus
      ? {
         'border-top': '1px solid',
         'border-left': '1px solid',
         'border-bottom': '1px solid',
         'border-color': '#1e7889',
        }
      : {},
    ]"
   >
    <SearchIcon class="w-5 h-5" aria-hidden="true" />
   </div>
   <input
    type="search"
    name="search"
    id="search"
    class="block w-full border-gray-300 rounded search pl-11 sm:text-sm focus:ring-0"
    @input="$emit('update:search', $event.target.value)"
    :placeholder="[!onFocus ? $t('header.search') : inputPlaceholder]"
    v-model="search"
    @focus="onFocus = true"
    @blur="onFocus = false"
   />
  </div>
 </div> -->
 <div class="pt-2 pb-3 sm:flex sm:items-center sm:justify-between">
  <div class="mt-3 sm:mt-0 sm:ml-4">
   <label for="mobile-search-candidate" class="sr-only">Search</label>
   <label for="desktop-search-candidate" class="sr-only">Search</label>
   <div class="flex rounded-md shadow-sm">
    <div class="relative flex-grow focus-within:z-10">
     <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 24 24"
       stroke-width="1.5"
       stroke="currentColor"
       class="w-5 h-5 text-gray-400"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
       />
      </svg>
     </div>
     <input
      v-model="search"
      type="text"
      name="mobile-search-candidate"
      id="mobile-search-candidate"
      class="block w-full pl-10 border-gray-300 rounded-md sm:hidden"
      @input="$emit('update:search', $event.target.value)"
      :placeholder="inputPlaceholder"
     />
     <input
      v-model="search"
      type="text"
      name="desktop-search-candidate"
      id="desktop-search-candidate"
      class="hidden w-full pl-10 border-gray-300 rounded-md sm:block sm:text-sm"
      @input="$emit('update:search', $event.target.value)"
      :placeholder="inputPlaceholder"
     />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { SortAscendingIcon, UsersIcon, SearchIcon } from "@heroicons/vue/solid";

export default {
 name: "SearchFilter",
 props: ["inputPlaceholder"],
 components: {
  SortAscendingIcon,
  SearchIcon,
  UsersIcon,
 },
 data() {
  return {
   onFocus: false,
   search: "",
  };
 },
};
</script>

<style scoped>
.search {
 -webkit-transition: all 0.5s;
 -moz-transition: all 0.5s;
 transition: all 0.5s;
}
.search:focus {
 background-color: #fff;
 border-color: #1e7889;
 margin-inline-end: 160px;
}
</style>
